import Axios from "axios";
import i18n from "@/plugins/i18n";
import moment from "moment";
import router from "@/router";
//import webstomp from 'webstomp-client';
//import Paho from 'paho-mqtt'; 
var workerLogin;

//var amqp = require('amqplib/callback_api');

export default {
  async ManageWorker({ dispatch, commit, state, getters }, request)
  {
    var worker;
    if (request[0].from == "LOGIN" || request[0].from == "TOKEN")
    {
      dispatch("cancelLogin");
      workerLogin = new Worker("webWorker.js");

      workerLogin.addEventListener("message", async function (response)
      {
        //Manage request
        if (response.data.to == "LOGIN")
        {
          dispatch("responseLogin", response);
        } else if (response.data.to == "TOKEN")
        {
          await dispatch("responseToken", [response, request[1]]);
        } else if (response.data.to == "ERROR")
        {
          dispatch("manageError", response);
          workerLogin.terminate();
        }
      });

      workerLogin.postMessage(request[0]);
    } else
    {
      worker = new Worker("webWorker.js");
      worker.addEventListener("message", async function (response)
      {
        if (response.data.to == "ERROR")
        {
          dispatch("manageError", response);
          worker.terminate();
        } else if (response.data.to == "LOGOUT")
        {
          //console.log(response.data.status);
          dispatch("responseLogOut", [response, request[1]]);
          worker.terminate();
        } else if (response.data.to == "REFRESH_TOKEN")
        {
          dispatch("responseRefreshToken", [response, request[1]]);
          worker.terminate();
        } else if (response.data.to == "INFOVEHICLES")
        {
          //console.log(response.data.data);
          commit("fillTable", response.data.data);
        } else if (response.data.to == "GETVERSIONS")
        {
          //console.log(response.data.data);
          if (response.data.status != 200) state.progressForUpdParameters = false;
          commit("fillVersionTable", response.data.data);
        } else if (response.data.to == "GET_FILTER_CATALOG")
        {
          //console.log(response.data.data);
          var responseList = response.data.data;
          if (response.data.status != 200) responseList = [];
          dispatch("FilterCatalogResponse", { response: responseList, type: request[1] });
          worker.terminate();
        } else if (response.data.to == "GET_FIRMWARE_DEVICES")
        {
          //console.log(response.data.data);
          if (response.data.status != 200) commit("loadingPT", false);
          dispatch("FirmwareDevicesResponse", response.data);
        } else if (response.data.to == "UPDATE_FIRMWARE_DEVICES")
        {
          if (response.data.status == 200)
          {
            commit("changeTextInDialogSendingUpdate");
            commit("openDialogSendingUpdate", true);
          } else
          {
            commit("openDialogSendingUpdate", false);
          }
        } else if (response.data.to == "GET_DEVICES_STATUS")
        {
          //console.log(response.data.data);
          if (response.data.status != 200) state.progressForVehInProgress = false;
          commit("fillDevicesStatusTable", response.data.data);
        } else if (response.data.to == "DELETE_FIRMWARE_VERSION")
        {
          //console.log(response.data.status);
          if (response.data.status == 200)
          {
            state.dialogVersionDeletedWithSuccess = true;
          }
          //console.log(response.data.data);
          dispatch("getVersions");
        } else if (response.data.to == "ADD_FIRMWARE_VERSION")
        {
          //console.log(response.data.data);
        }
        else if (response.data.to == "GET_ORDERS_LIST")
        {
          //console.log(response.data.data);
          if (response.data.status != 200) state.progressForList = false;
          commit("fillListTable", response.data.data);
        }
        else if (response.data.to == "GET_ORDER_DETAILS")
        {
          //console.log(response.data.data);
          if (response.data.status != 200) state.progressForOrderDetails = false;
          commit("fillOrderDetailsTable", response.data.data);
        }
      });
      worker.postMessage(request[0]);
    }
  },
  manageError: ({ state, commit, dispatch }, response) =>
  {
    if (
      response.data.status == 500 ||
      response.data.status == 400 ||
      response.data.status == 403
    )
    {
      commit("snackbar", {
        snackbar: true,
        text: i18n.t("error400"),
      });

      if (router.app.$route.path == "/login")
      {
        commit("progresslogin", false);
      }
      else
      {
        //Estrategia para los demás paths
        commit("openDialogSendingUpdate", false);
      }

    } else if (response.data.status == 401)
    {
      commit("snackbar", {
        snackbar: true,
        text: i18n.t("autorization"),
      });

      commit("auth", false);
      localStorage.removeItem("auth");
    }
  },
  //Get login for user
  async getLogin({ dispatch, commit, state })
  {
    if (state.username != "" && state.password != "")
    {
      if (
        !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
          state.username
        )
      )
      {
        commit("snackbar", { snackbar: true, text: i18n.t("invalidUs") });
        commit("progresslogin", false);
        commit("loginBtn", i18n.t("loginCard"));
      } else
      {
        const url =
          state.NeutrinoUrl +
          "login?Email=" +
          state.username +
          "&password=" +
          state.password;

        var request = {
          from: "LOGIN",
          URL: url,
          method: "GET",
          responseType: "",
          headers: "",
          params: "",
        };
        await dispatch("ManageWorker", [request]);
      }
    } else
    {
      commit("snackbar", { snackbar: true, text: i18n.t("tryAgain") });
      commit("progresslogin", false);
      commit("loginBtn", i18n.t("loginCard"));
    }
  },

  responseLogin: ({ dispatch, commit, getters, state }, response) =>
  {
    if (response.data.status == "200" || response.data.status == 200)
    {
      commit("responseLogin", response.data.data);

      if (response.data.data.UserAccessList.length > 1)
      {
        commit("progresslogin", false);
        commit("loginBtn", i18n.t("loginCard"));
      } else
      {
        localStorage.setItem(
          "UserDataAccessList",
          JSON.stringify(response.data.data.UserAccessList[0])
        );
        dispatch("getToken", response.data.data.UserAccessList[0]);
      }
    } else if (response.data.status != "200" || response.data.status != 200)
    {
      var message =
        response.data.status == 204 ? i18n.t("invalidUs") : i18n.t("loginErr");
      commit("snackbar", {
        snackbar: true,
        text: message,
      });
      commit("progresslogin", false);
      commit("loginBtn", i18n.t("loginCard"));
    }
  },

  cancelLogin: () =>
  {
    if (workerLogin != undefined)
    {
      workerLogin.terminate();
      workerLogin = undefined;
    }
  },

  //Get token auth for user logged
  async getToken({ dispatch, commit, state }, userData)
  {
    commit("setMasterFunctions", userData.Level.toUpperCase() == "MASTER");

    let forbiddenAccess = false;

    if (userData.Level.toUpperCase() != "MASTER" && state.environmentName != "TG") forbiddenAccess = true;

    if (userData.Level.toUpperCase() != "MASTER" && userData.Level.toUpperCase() != "DEALER" && userData.Level.toUpperCase() != "CLIENT") forbiddenAccess = true;

    if (forbiddenAccess == true) {
      commit("loginBtn", i18n.t("loginCard"));
      commit("progresslogin", false);
      commit("snackbar", { snackbar: true, text: i18n.t("masterFunction") });
      return;
    }

    let user_data = JSON.parse(localStorage.getItem("userData"));

    //dispatch("checkPermits", userData);
    if (state.invalidUser != true)
    {
      var AccessIdPass = localStorage.getItem("AccessId");
      if (
        AccessIdPass != undefined ||
        AccessIdPass != "" ||
        AccessIdPass != null
      )
      {
        if (userData.AccessId != AccessIdPass)
        {
          dispatch("DeletedLocalStorage");
          localStorage.setItem("AccessId", userData.AccessId);
        }
      } else
      {
        localStorage.setItem("AccessId", userData.AccessId);
      }

      state.userData = user_data;
      localStorage.setItem("userData", JSON.stringify(user_data));

      localStorage.setItem("userName", state.username);
      const token = {
        Email: state.username,
        UserAccessId: userData.AccessId,
        DealerId: userData.DealerId,
        ClientApplicationId: "bti.web.firmwareManager." + userData.AccessId,
      };
      const url = state.NeutrinoUrl + "login/token";

      var request = {
        from: "TOKEN",
        URL: url,
        method: "POST",
        responseType: "",
        headers: {
          headers: { "Content-type": "application/json" },
        },
        params: token,
      };
      await dispatch("ManageWorker", [request, userData]);
    }
  },

  //Refresh token for current user
  //UserData = [UserAccess, Email, refreshtoken] // Is optional
  async refreshToken({ dispatch, commit, state }, userData)
  {
    var offline = JSON.parse(localStorage.getItem("offline"));
    if (!offline)
    {
      if (state.authenticated)
      {
        var userAccess =
          userData == undefined ? state.SelectedAccess : userData[1];
        var username =
          userData == undefined
            ? localStorage.getItem("userName")
            : userData[0].Email;
        var tokenInfo =
          userData == undefined
            ? localStorage.getItem("refreshToken")
            : userData[2];
        const token = {
          Email: username,
          UserAccessId: userAccess.AccessId,
          DealerId: userAccess.DealerId,
          ClientApplicationId: "bti.web.firmwareManager." + userAccess.AccessId,
        };
        const url = state.NeutrinoUrl + "login/refreshtoken?Token=" + tokenInfo;

        var request = {
          from: "REFRESH_TOKEN",
          URL: url,
          method: "POST",
          responseType: "",
          headers: {
            "Content-type": "application/json",
          },
          params: token,
        };

        await dispatch("ManageWorker", [request, userAccess]);
      }
    }
  },

  responseRefreshToken: ({ dispatch, commit, state }, response) =>
  {
    if (response[0].data.status == "200" || response[0].data.status == 200)
    {
      var payload = [response[0].data.data, response[1]];
      commit("getToken", payload);
    } else
    {
      //session expired
      //do something
      dispatch("manageError", response);
    }
  },

  async responseToken({ dispatch, commit, state, getters }, response)
  {
    if (response[0].data.status == "200" || response[0].data.status == 200)
    {
      var userData = JSON.parse(localStorage.getItem("UserDataAccessList"));
      //Set data in vuex state
      commit("getToken", [response[0].data.data, userData]);
      commit("auth", true);
      localStorage.setItem("refreshToken", response[0].data.data.refresh_token);
      commit("progresslogin", false);
      commit("setValuesLanguage");
      // dispatch("beforeInit", response[1]);

      //Timer for refresh token
      dispatch("refreshTimer");
    } else
    {
      commit("snackbar", { snackbar: true, text: i18n.t("tryAgain") });
      commit("progresslogin", false);
    }
  },

  checkPermits: ({ dispatch, commit, state, getters }, userData) =>
  {
    //Actualmente Firmware manager es una herramienta interna (solo para usuarios nivel master), cuando sea para otros niveles de usuario se podrá revisar su permiso
    if (userData.Level != "Master")
    {
      var selectedAccess = userData.ServiceList;
      if (typeof selectedAccess === "object" && selectedAccess !== null)
      {
        //se revisa permiso del acceso seleccionado selectedAccess

        // if (
        //   selectedAccess.AlertTruck == true ||
        //   selectedAccess.AlertFuel_Individual == true ||
        //   selectedAccess.Tracking == true
        // ) {
        // } else {
        //   commit("invalidUser", true);
        // }
      } else
      {
        return commit("invalidUser", true);
      }
    }
  },

  DeletedLocalStorage: ({ }) =>
  {
    localStorage.removeItem("DealerId");
    localStorage.removeItem("dealerItems");
    localStorage.removeItem("DealerSelectedId");
    localStorage.removeItem("ClientSelectedId");
    localStorage.removeItem("userData");
    localStorage.removeItem("userName");
    localStorage.removeItem("SelectedAccess");
    localStorage.removeItem("auth");
  },

  handleConnectivityChange: ({ dispatch, commit, state }, status) =>
  {
    if (status == false)
    {
      localStorage.setItem("offline", true);
      commit("snackbar", {
        snackbar: true,
        text: i18n.t("offline"),
      });
    } else
    {
      localStorage.setItem("offline", false);
      if (router.app.$route.path != "/login" || router.app.$route.path != "/")
      {
        if (state.loginOffline)
        {
          commit("loginOffline", false);
          var userAccess = JSON.parse(localStorage.getItem("SelectedAccess"));
          dispatch("getToken", userAccess);
        } else
        {
          var userAccess = JSON.parse(localStorage.getItem("SelectedAccess"));
          var refreshToken =
            localStorage.getItem("refreshToken") != null ||
              localStorage.getItem("refreshToken") != undefined
              ? localStorage.getItem("refreshToken")
              : state.refreshToken;
          var userData = { Email: localStorage.getItem("userName") };
          var payload = [userData, userAccess, refreshToken];
          dispatch("refreshToken", payload);
        }
      }
    }
  },
  //LogOut can delete access token or logout user
  //Payload = [AccessId, token] // Is optional
  async logOutApp({ dispatch, commit, state }, payload)
  {
    var offline = JSON.parse(localStorage.getItem("offline"));
    if (!offline)
    {
      var token = state.token;
      if (token == null || token == "" || token == undefined)
      {
        token = localStorage.getItem("Token");
      }
      var userData = state.SelectedAccess.AccessId;
      if (userData == null || userData == "" || userData == undefined)
      {
        userData = JSON.parse(localStorage.getItem("UserDataAccessList"));
        userData = userData.AccessId;
      }
      const url = state.NeutrinoUrl + "login";

      var request = {
        from: "LOGOUT",
        URL: url,
        method: "DELETE",
        responseType: "",
        headers: {
          Authorization: "bearer " + token,
          "Content-type": "application/json",
        },
        params: "",
      };
      //console.log(request);
      var data = payload == null ? [userData, true] : null;
      //console.log(data);
      await dispatch("ManageWorker", [request, data]);
    } else
    {
      var userData = state.SelectedAccess.AccessId;
      if (userData == null || userData == "" || userData == undefined)
      {
        userData = JSON.parse(localStorage.getItem("UserDataAccessList"));
        userData = userData.AccessId;
      }
      var data = payload == null ? [userData, true] : null;
      var responseWoker = {
        data: {
          data: "",
          status: 200,
          to: "",
          payload: "",
        },
      };
      dispatch("responseLogOut", [responseWoker, data]);
    }
  },
  responseLogOut: ({ dispatch, commit, state }, response) =>
  {
    commit("auth", false);
  },
  //Apply timmer for call refresh token
  refreshTimer: ({ dispatch, commmit, state }, item) =>
  {
    setTimeout(() =>
    {
      dispatch("refreshToken");
    }, 1080000);

    //change timer 1080000 1080000
  },

  async getVersions({ dispatch, commit, state })
  {
    var offline = JSON.parse(localStorage.getItem("offline"));
    if (!offline)
    {
      var token =
        state.token == null || state.token == ""
          ? localStorage.getItem("Token")
          : state.token;

      const url = state.NeutrinoUrl + "operation/FTP/firmwareFiles";
      //const url = 'https://run.mocky.io/v3/406d0ff6-8cd5-4759-994c-8d956c702f7d';

      var request = {
        from: "GETVERSIONS",
        URL: url,
        method: "GET",
        responseType: "",
        headers: "",
        headers: {
          Authorization: "bearer " + token,
          "Content-type": "application/json",
        },
        params: "",
      };
      await dispatch("ManageWorker", [request]);
    } else
    {
      commit("snackbar", {
        snackbar: true,
        text: i18n.t("later"),
      });
    }
  },

  //Get filter vehicles
  async getCatalog({ dispatch, getters, state, commit })
  {
    let userData = state.SelectedAccess;

    if (userData == null || userData == "" || userData == undefined)
    {
      userData = JSON.parse(localStorage.getItem("SelectedAccess"));
      state.SelectedAccess = userData;
    }

    var level = userData.Level.toUpperCase();

    commit("setMasterFunctions", level == "MASTER");

    if (level == "MASTER")
    {
      dispatch("getFilterCatalog", { type: "dealers", dealer: "" });
      return;
    } else
    {
      var Dealers = [{
        DealerId: state.SelectedAccess.DealerId,
        Name: state.SelectedAccess.DealerName,
      }];
      commit("setDealers", Dealers);

      var delaerselected = getters.getAccessFilter([
        state.dealerItems,
        state.SelectedAccess.DealerId,
        "DealerId"
      ]);

      commit("setDealerAccount", delaerselected);

      if (level == "DEALER")
      {
        dispatch("getFilterCatalog", { type: "clients", dealer: state.SelectedAccess.DealerId });
        dispatch("GetFirmwareDevices", { did: state.SelectedAccess.DealerId, cid: "" });
      }
      else if (level == "CLIENT")
      {
        var Clients = [{
          DealerId: state.SelectedAccess.ClientId,
          Name: state.SelectedAccess.ClientName,
        }];
        commit("setClients", Clients);

        dispatch("GetFirmwareDevices", { did: state.SelectedAccess.DealerId, cid: state.SelectedAccess.ClientId });
      }
    }

  },

  //Get filter vehicles
  async getFilterCatalog({ dispatch, getters, state, commit }, { type, dealer })
  {
    var offline = JSON.parse(localStorage.getItem("offline"));
    if (!offline)
    {
      var token =
        state.token == null || state.token == ""
          ? localStorage.getItem("Token")
          : state.token;

      var extension = type == "dealers" ? "management/dealers" : "management/clients?DealerId=" + dealer;
      const url = state.NeutrinoUrl + extension;

      var request = {
        from: "GET_FILTER_CATALOG",
        URL: url,
        method: "GET",
        responseType: "",
        headers: {
          Authorization: "bearer " + token,
          "Content-type": "application/json",
        },
        params: "",
      };
      await dispatch("ManageWorker", [request, type]);
    } else
    {
      commit("snackbar", {
        snackbar: true,
        text: i18n.t("later"),
      });
    }
  },

  FilterCatalogResponse({ dispatch, commit, getters, state }, { response, type })
  {
    if (type == "dealers")
    {
      if (response.length > 1)
      {
        //formatear lista de dealers por ABC y con camel case
        var responseList = getters.OrderBy(response);
        //establecer la lista de dealers
        commit("setDealers", responseList);
        //establecer la lista de clientes del primer dealer
        dispatch("getFilterCatalog", { type: "clients", dealer: responseList[0].DealerId });
        localStorage.setItem("DealerSelectedId", JSON.stringify(responseList[0]));
        dispatch("GetFirmwareDevices", { did: responseList[0].DealerId, cid: "" });
      } else
      {
        commit("setDealers", response);
      }

    } else
    { //clientes       
      if (response.length > 1)
      {
        //formatear lista de clientes por ABC y con camel case
        var responseList = getters.OrderBy(response);
        //establecer la lista de clientes
        commit("setClients", responseList);
        //llamar obtener vehiculos
        //dispatch("getFilterCatalog", {type: "clients", dealer: responseList[0].DealerId});              
      } else
      {
        commit("setClients", response);
      }
    }
  },

  async GetFirmwareDevices({ dispatch, commit, getters, state }, { did, cid })
  {
    var offline = JSON.parse(localStorage.getItem("offline"));
    if (!offline)
    {
      var token =
        state.token == null || state.token == ""
          ? localStorage.getItem("Token")
          : state.token;

      const url = state.NeutrinoUrl + "operation/firmware/devices?dealerid=" + did + "&clientid=" + cid;

      var request = {
        from: "GET_FIRMWARE_DEVICES",
        URL: url,
        method: "GET",
        responseType: "",
        headers: {
          Authorization: "bearer " + token,
          "Content-type": "application/json",
        },
        params: "",
      };
      //console.log(request);
      await dispatch("ManageWorker", [request]);
    }
  },

  FirmwareDevicesResponse({ dispatch, commit, getters, state }, response)
  {
    var DevicesList = [];
    var DevicesListMtt = [];
    //console.log(response);
    if (response.status == 200 && response.data.length > 0)
    {

      response.data.forEach(element =>
      {
        //console.log(element);
        var stateAVL = getters.getFirmwareState(element.State);
        /*Fechas inválidas:
        *1983-03-24T00:00:00
        *0001-01-01T00:00:00
        *1976-07-30T00:00:00*/

        //Device info
        let date, dateToMilliseconds, dateMtt, dateMttToMilliseconds, dateLastTransmittion, dateLastTransmittionToMilliseconds;
        if (element.UpdateDate == "1976-07-30T00:00:00" || element.UpdateDate == "0001-01-01T00:00:00" || element.UpdateDate == "1983-03-24T00:00:00")
        {
          date = "ND";
          dateToMilliseconds = 0;
        } else
        {
          //fecha se convierte en milliseconds para poder ordenar columna
          dateToMilliseconds = moment(element.UpdateDate).valueOf();
          date = getters.formatDateCustom(element.UpdateDate.replace("T", " "), true);
        }

        var notes = element.Notes == "" ? "ND" : element.Notes;
        var requestedBy = element.RequestedBy == "" ? "ND" : element.RequestedBy;
        let nick = element.Nick == "" || element.Nick == null ? "ND" : element.Nick;
        var deviceInfo = element.MovilId + " (" + nick + ")";
        var firmwareVersion = element.FirmwareVersion == "" ? i18n.t("unknown") : element.FirmwareVersion;

        //MTT info
        var stateMtt = getters.getFirmwareState(element.MttInfo.State);

        if (element.MttInfo.UpdateDate == "1976-07-30T00:00:00" || element.MttInfo.UpdateDate == "0001-01-01T00:00:00" || element.MttInfo.UpdateDate == "1983-03-24T00:00:00")
        {
          dateMtt = "ND";
          dateMttToMilliseconds = 0;
        } else
        {
          dateMttToMilliseconds = moment(element.MttInfo.UpdateDate).valueOf();
          dateMtt = getters.formatDateCustom(element.MttInfo.UpdateDate.replace("T", " "), true);
        }


        var notesMtt = element.MttInfo.Notes == null ? "ND" : element.MttInfo.Notes;
        var requestedByMtt = element.MttInfo.RequestedBy == null ? "ND" : element.MttInfo.RequestedBy;
        var firmwareVersionMtt = element.MttInfo.FirmwareVersion == null ? i18n.t("unknown") : element.MttInfo.FirmwareVersion;

        if (element.LastTransmision == "1976-07-30T00:00:00" || element.LastTransmision == "0001-01-01T00:00:00" || element.LastTransmision == "1983-03-24T00:00:00")
        {
          dateLastTransmittion = "ND";
          dateLastTransmittionToMilliseconds = 0;
        } else
        {
          dateLastTransmittionToMilliseconds = moment(element.LastTransmision).valueOf();
          dateLastTransmittion = getters.formatDateCustom(element.LastTransmision.replace("T", " "), false);
        }


        var elementResponse = {
          MovilId: deviceInfo,
          State: stateAVL,
          RequestedBy: requestedBy,
          FirmwareVersion: firmwareVersion,
          UpdateDate: date,
          UpdateDateToMs: dateToMilliseconds,
          Notes: notes,
          isSelectable: true,
          isOnline: element.Online == 1 ? true : false,
          lastTransmission: dateLastTransmittion,
          lastTransmissionToMs: dateLastTransmittionToMilliseconds
        };

        var elementResponseMtt = {
          MovilId: deviceInfo,
          State: stateMtt,
          RequestedBy: requestedByMtt,
          FirmwareVersion: firmwareVersionMtt,
          UpdateDate: dateMtt,
          UpdateDateToMs: dateMttToMilliseconds,
          Notes: notesMtt,
          isSelectable: true,
          isOnline: element.Online == 1 ? true : false,
          lastTransmission: dateLastTransmittion,
          lastTransmissionToMs: dateLastTransmittionToMilliseconds
        };

        //Para quienes NO sean nivel master, solo podrán ver los equipos PC41
        if (state.isMaster == false) {
          if (element.MovilId.includes("PC41")) DevicesList.push(elementResponse);
        } else {
          DevicesList.push(elementResponse);
        }

        //para mtt actualmente solo se incluyen tr71 y suntech
        if (element.MovilId.includes("TR71") || element.MovilId.includes("STU")) DevicesListMtt.push(elementResponseMtt);
      });
      commit("loadingPT", false);

    }

    commit("setFirmwareDevicesList", DevicesList);
    commit("setFirmwareMttDevicesList", DevicesListMtt);
  },

  async ConnectRabbitMQ2({ dispatch, getters, state, commit })
  {
    try
    {
      console.log(webstomp);

      function onMessage(user, msg)
      {
        console.log('MESSAGE', user, msg.body);
      }
      function onError(user, err)
      {
        console.log('DISCONNECTED', user, err);
      }
      function onConnect(user)
      {
        console.log('CONNECTED', user);
        user.subscribe('/fwf', onMessage.bind(this, user));
      }

      var url = 'ws://comm.utraxweb.com:5671',
        login = 'fw-monitor', password = 'bAsiYm&S@botFe',
        options = { debug: false, protocols: webstomp.VERSIONS.supportedProtocols() };
      var client = webstomp.over(new WebSocket(url), options);
      console.log(client);
      client.connect(login, password, onConnect.bind(this, client), onError.bind(this, client));

      console.log(client);
    } catch (e)
    {
      console.log(e);
    }

  },

  async ConnectRabbitMQ({ dispatch, getters, commit, state })
  {
    // var brokerEndpoint = "comm.utraxweb.com";
    // var port = 5671;
    // var client = null;
    // var clientappid = "webtracking-01003-" + parseInt(Math.random() * 100, 10);
    // var login = 'fw-monitor', password = 'bAsiYm&S@botFe';
    // var applicationName = "ClientNeutrino";

    // client = new Paho.Client(brokerEndpoint, port, "/ws", clientappid);
    // client.onConnectionLost = function (responseObject) {
    //     console.log("Connection lost. " + responseObject.errorMessage);
    // };

    // client.onMessageArrived = function (message) {
    //     console.log("RCV < " + message.destinationName + " PAYLOAD " + message.payloadString); 
    //     var notification = JSON.parse(message.payloadString);
    //   console.log(notification);
    // };

    // client.onConnected = function (reconn, uri) {
    //     console.log("Connected, reconnected: " + reconn + ", URI: " + uri);
    // };

    // var options = {
    //     userName: login,
    //     password: password,
    //     reconnect: true,
    //     timeout: 3,
    //     keepAliveInterval: 30,
    //     onSuccess: function () {
    //         console.log("Connected to " + brokerEndpoint + ":" + port);
    //         client.subscribe(clientappid, {
    //             qos: 0
    //         });
    //     },

    //     onFailure: function (message) {
    //         console.log("connection fail. " + message.errorMessage);
    //     }
    // };

    // if (location.protocol == "https:") {
    //     options.useSSL = true;
    // }

    // console.log("Connecting to " + brokerEndpoint + ":" + port);
    // client.connect(options);

  },

  async updateFirmwareDevices({ dispatch, getters, state, commit }, params)
  {
    try
    {
      var offline = JSON.parse(localStorage.getItem("offline"));
      if (!offline)
      {
        var token =
          state.token == null || state.token == ""
            ? localStorage.getItem("Token")
            : state.token;

        const url = state.NeutrinoUrl + "operation/firmware/update/devices";

        var request = {
          from: "UPDATE_FIRMWARE_DEVICES",
          URL: url,
          method: "POST",
          responseType: "",
          headers: {
            Authorization: "bearer " + token,
            "Content-type": "application/json",
          },
          params: params
        };

        await dispatch("ManageWorker", [request]);
      } else
      {
        commit("snackbar", {
          snackbar: true,
          text: i18n.t("later"),
        });
      }
    } catch (e)
    {
      alert("updateFirmwareDevices, Excepción: " + e.message);
    }
  },

  async getDevicesStatus({ dispatch, getters, state, commit })
  {
    try
    {
      var offline = JSON.parse(localStorage.getItem("offline"));
      if (!offline)
      {
        var token =
          state.token == null || state.token == ""
            ? localStorage.getItem("Token")
            : state.token;

        const url = state.NeutrinoUrl + "operation/firmware/devices/status";

        var request = {
          from: "GET_DEVICES_STATUS",
          URL: url,
          method: "GET",
          responseType: "",
          headers: {
            Authorization: "bearer " + token,
            "Content-type": "application/json",
          },
          params: ""
        };
        await dispatch("ManageWorker", [request]);
      } else
      {
        commit("snackbar", {
          snackbar: true,
          text: i18n.t("later"),
        });
      }
    } catch (e)
    {
      alert("getDevicesStatus, Excepción: " + e.message);
    }
  },
  async deleteFirmwareVersion({ dispatch, getters, state, commit }, FtpFileName)
  {
    try
    {
      var offline = JSON.parse(localStorage.getItem("offline"));
      if (!offline)
      {
        var token =
          state.token == null || state.token == ""
            ? localStorage.getItem("Token")
            : state.token;

        const url = state.NeutrinoUrl + "operation/FTP/DeleteFirmwareFile?FtpFileName=" + FtpFileName;

        var request = {
          from: "DELETE_FIRMWARE_VERSION",
          URL: url,
          method: "DELETE",
          responseType: "",
          headers: {
            Authorization: "bearer " + token,
            "Content-type": "application/json",
          },
          params: ""
        };
        await dispatch("ManageWorker", [request]);
      } else
      {
        commit("snackbar", {
          snackbar: true,
          text: i18n.t("later"),
        });
      }
    } catch (e)
    {
      alert("deleteFirmwareVersion, Excepción: " + e.message);
    }
  },
  async getOrdersList({ dispatch, getters, state, commit })
  {
    try
    {
      var offline = JSON.parse(localStorage.getItem("offline"));
      if (!offline)
      {
        var token =
          state.token == null || state.token == ""
            ? localStorage.getItem("Token")
            : state.token;

        //const url = state.NeutrinoUrl + "";
        const url = "https://run.mocky.io/v3/033d06b1-7c0c-48d9-921e-2cc8da0c2328";

        var request = {
          from: "GET_ORDERS_LIST",
          URL: url,
          method: "GET",
          responseType: "",
          headers: {
            //Authorization: "bearer " + token,
            "Content-type": "application/json",
          },
          params: ""
        };
        await dispatch("ManageWorker", [request]);
      } else
      {
        commit("snackbar", {
          snackbar: true,
          text: i18n.t("later"),
        });
      }
    } catch (e)
    {
      alert("getOrdersList, Excepción: " + e.message);
    }
  },
  async getOrderDetails({ dispatch, getters, state, commit })
  {
    try
    {
      var offline = JSON.parse(localStorage.getItem("offline"));
      if (!offline)
      {
        var token =
          state.token == null || state.token == ""
            ? localStorage.getItem("Token")
            : state.token;

        //const url = state.NeutrinoUrl + "";
        const url = "https://run.mocky.io/v3/50eccd70-82bd-4910-873f-2b956becae8b";

        var request = {
          from: "GET_ORDER_DETAILS",
          URL: url,
          method: "GET",
          responseType: "",
          headers: {
            //Authorization: "bearer " + token,
            "Content-type": "application/json",
          },
          params: ""
        };
        await dispatch("ManageWorker", [request]);
      } else
      {
        commit("snackbar", {
          snackbar: true,
          text: i18n.t("later"),
        });
      }
    } catch (e)
    {
      alert("getOrderDetails, Excepción: " + e.message);
    }
  },
};
