import Vue from 'vue'
import Vuex from 'vuex'
import VueAxios from "vue-axios"
import axios from "axios"
import getters from "./getters"
import actions from "./actions"
import state from "./state"
import mutations from "./mutations"

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  //strict: true,
  state,
  getters,
  mutations,
  actions,
})
