import i18n from "@/plugins/i18n";
import router from "@/router";
import moment from "moment";

export default {
      OrderBy: (state) => (payload) => {
    var format = [];
    if (payload.length > 0) {
      var shorted = payload.sort(function compare(a, b) {
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        return 0;
      });

      shorted.forEach((element) => {
        var formantReplace = element.Name.replace(/\w\S*/g, function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
        element.Name = formantReplace;
        format.push(element);
      });
    }
    return format;
    },
 getAccessFilter: (state) => (payload) => {
    var arrAccess = payload[0];
    var result = {};
    if (arrAccess.length > 0) {
      arrAccess.map((item) => {
        if (payload[2] == "DealerId")
          if (item != i18n.t("all") && item.DealerId == payload[1])
            result = item;
        if (payload[2] == "ClientId")
          if (item != i18n.t("all") && item.ClientId == payload[1])
            result = item;
        if (payload[2] == "AccountId")
          if (item != i18n.t("all") && item.AccountId == payload[1])
            result = item;
        if (payload[2] == "GroupId")
          if (item != i18n.t("all") && item.GroupId == payload[1])
            result = item;
      });
    }
    return result;
  },
  formatDateCustom: (state) => (value, isUTC) => {
    //Como segundo parámetro tenemos si la fecha es UTC, si es así se trata como UTC (moment.utc) y se convierte a local (dateFormat.local)
    var datefinal = "";
    var lang = localStorage.getItem("language");
    //Se obtiene obj de momentjs con el formato de fecha e indicando que es utc
    var dateFormat = isUTC ? moment.utc(value, "YYYY-MM-DD HH:mm") : moment(value, "YYYY-MM-DD HH:mm");
    if (dateFormat === "Invalid date") {
      return value;
    } else {
      //Se indica que queremos la fecha local y el formato adecuado para español o inglés
      if (lang.toUpperCase() == "ES") {
        datefinal = isUTC ? dateFormat.local().format("DD/MMM/YYYY hh:mm A") : dateFormat.format("DD/MMM/YYYY hh:mm A");
      } else {
        datefinal = isUTC ? dateFormat.local().format("MMM/DD/YYYY hh:mm A") : dateFormat.format("MMM/DD/YYYY hh:mm A");
      }
      return datefinal;
    }
  },
   
  getFirmwareState: (state) => (value) => {
           var state = i18n.t("unknown");
              switch (value) {
                case 255:
                  state = i18n.t("unknown");
                  break;
                case 0:
                  state = i18n.t("pending");
                  break;
                case 1:
                  state = i18n.t("isInProgress");
                  break;
                case 2:
                  state = i18n.t("updated");
                  break;
                case 3:
                  state = i18n.t("canceled");
                  break;
                case 4:
                  state = i18n.t("failed");
                  break;
    }
    return state;
   },

   getUsername: (state) => (value) => {
    let username = state.username != "" ? state.username : JSON.parse(localStorage.getItem("userData")).Email;
    return username;
  },

  getUserLevel: (state) => (value) => {
    let userLevel = JSON.parse(localStorage.getItem("UserDataAccessList")).Level;
    return userLevel;
  }
}