import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi" // default - only for display purposes
    },
    theme: {
        dark: false,
        themes: {
          light: {
            primary: "#8FAE2D"
          },
          dark: {
            primary: "#8FAE2D"
          }
        }
    }
});
