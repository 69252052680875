import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import Resize from 'vuetify/lib/directives/resize';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.Resize),expression:"Resize"}],attrs:{"id":"app"}},[_c(VApp,[(this.$store.state.authenticated)?[_c('router-view',{on:{"authenticated":_vm.setAuthenticated}})]:[_c('router-view',{on:{"authenticated":_vm.setAuthenticated}})]],2),_c(VSnackbar,{attrs:{"multi-line":_vm.mode === 'multi-line',"right":_vm.x === 'right',"timeout":_vm.timeout,"top":true,"vertical":_vm.mode === 'vertical'},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":_vm.colorTheme,"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }