import i18n from "@/plugins/i18n";
import logoBoson from "@/assets/LogoBosonWhite.png";
//import avatar from "@/assets/avatar-btn.png";

export default {
  NeutrinoUrl: "https://ws-neutrino.azurewebsites.net/api/v2/",//BOSON
  //NeutrinoUrl: "https://apprgps.tresguerras.com.mx/ws/utrax/neutrino/api/v2/",//TG
  //NeutrinoUrl: "http://localhost:50226/api/v2/",//ws local
  environmentName: "BOSON",
  //environmentName: "TG",
  //common
  production: true,
  isMaster: false,
  currentFirmwareFile: "PHA240717D09.uc3",
  colorTheme: "#8FAE2D",
  loginBtn: "",
  hoveres: "",
  hoveren: "",
  authenticated: false,
  remember: false,
  invalidUser: false,
  progresslogin: false,
  imageLogo: logoBoson,
  loginBtn: "",
  styleLogo: "margin: 0px; width: 450px;",
  padding: "padding: 2%;",
  styleMargin: "margin: 5px;",
  styleListOfAccess:
    "max-height: 67%;overflow-y: scroll;height: 357px;max-width: 100%;display: inline-block;",
  worldVisible: true,
  valid: true,
  tokenRules: [],
  emailRules: [],
  username: "",
  password: "",
  windowSize: {},
  mobileView: true,
  //All list of access
  UserAccessList: [],
  //Only selected access
  SelectedAccess: [],
  //All data of user
  userData: [],
  //Selected filter acccess
  SelectedUserAccess: [],
  //All list of access
  multipleUserData: [],
  data: "",
  multipleAccess: false,
  search: "",
  setLocale: "enMX",
  //for snackbar
  snackbar: false,
  selected: [],
  selectedVal: [],
  numberpages: 0,
  y: "top",
  x: null,
  mode: "",
  timeout: 3000,
  text: "",
  x: 0,
  y: 0,
  //Table of multiple access
  headers: [],
  datitos: [],
  error: null,
  //Token auth
  token: "",
  //All info of token
  tokenInfo: "",
  //Token for refresh new token
  refreshToken: "",
  //FILTER VEHICLES
  DealerId: "",
  headersFilterV: [],
  dealersDisabled: false,
  clientsDisabled: false,
  dealerSelected: {},
  clientSelected: {},
  dealerItems: [],
  clientItems: [],
  all: "",
  vehicles: [],
  dealers: [],
  clients: [],
  accounts: [],
  groups: [],
  noResults: "",
  disableFV: true,
  dealerAttach: false,
  clientAttach: false,
  firmwareVersionAttach: false,
  //HOME
  headersVersion: [],
  itemsVersion: [],
  headersFirmwareUpdate: [],
  itemsPrincipalTable: [],
  fileNameRules: [],
  infoVeh: [],
  infoVehMtt: [],
  progressPrincipalTable: false,
  progressVersionTable: false,
  fullResponse: [],
  progressForUpdParameters: false,
  progressForVehInProgress: false,
  headersDevicesStatus: [],
  itemsDevicesStatus: [],
  dialogVersionDeletedWithSuccess: false,
  sendingUpdate: false,
  textDialog: "",

  //ORDERS LIST
  headersList: [],
  itemsList: [],
  progressForList: false,
  headerOrderDetails: [],
  itemsOrderDetails: [],
  progressForOrderDetails: false

};
