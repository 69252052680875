import Vue from "vue";
import VueI18n from "vue-i18n";
import english from "../assets/Languages/en";
import spanish from "../assets/Languages/es";

Vue.use(VueI18n);

const messages = {
  es: spanish,
  en: english
};

const i18n = new VueI18n({
  locale: "es",
  fallbackLocale: "en",
  messages
});

//Export Translate
export default i18n;
