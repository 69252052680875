const en = {
  //Common
  cancel: "Cancel",
  search: "Search",
  accept: "OK",
  continue: "Next",
  back: "Back",
  noData: "No data",
  apply: "Apply",
  noSelection: "No selection",
  of: "of",
  warning: "Warning",
  notice: "Notice",
  close: "Close",

  //Login
  loginCard: "Login",
  selectUser: "Select",
  login: "Login",
  user: "User",
  pass: "Password",
  spa: "Spanish",
  eng: "English",
  access: "ACCESS",
  rights: "All rights reserved.",
  level: "Level",
  ref: "Reference",
  status: "Status",
  rows: "Rows per page",
  invalidUs: "Invalid data",
  invalidPass: "Invalid password",
  invalidEmail: "Enter a valid email address",
  tryAgain: "Invalid user. try again.",
  loginErr: "Connection cannot be established, try later",
  rememberme: "Remember me",
  invalidUser: "No privileges to enter",
  subheader: "Available access profiles",
  //Reports
  deleteConfirm: "Are you sure to delete the selected report?",
  delete: "Delete",
  download: "Download",
  new: "New report",
  name: "Name",
  date: "Date created",
  col: "Columns",
  vehicles: "Vehicles",
  type: "Report type",
  periodTitle: "Period",
  selectAll: "Select all",
  available: "Available vehicles",
  noResults: "No results found",
  asist: "New report wizard",
  commandSetting: "Commands setup",
  titleDes: "Performance report",
  nameReport: "File name",
  period: "Report period",
  selectV: "Selected vehicles",
  sendEmail: "Send email",
  selCols: "Select available columns",
  startDate: "Start date",
  endDate: "End date",
  type2: "Report yype",
  distance: "Total distance traveled",
  usedfuel: "Total fuel used",
  drivefuel: "Fuel consumption on drive",
  ralentifuel: "Fuel consumption idle",
  rendbruto: "Gross performance",
  consumeperhour: "Consumption per hour",
  timedriving: "Driving time",
  timeralenti: "Idle time",
  finalfuel: "Final fuel",
  timepto: "PTO time",
  percentPto: "% PTO",
  fuelpto: "PTO fuel",
  speedpromedy: "Average speed",
  speedmax: "Max speed",
  rpmexcess: "RPMs in excess",
  maxrpm: "Max RPMs",
  exceptions: "In transit incidents",
  finaldistance: "Final distance",
  await: "Working...",
  wrongConfirmation: "Unable to create the report. Try again.",
  CreatedBy: "Created by",
  Status: "Status",
  MessageReport:
    "The time to create the report will depend on the number of vehicles and selected dates",
  PerformanceReport: "Performance report",
  ExceptionReport: "Exceptions report",
  //Filter vehicles
  filterVehicleSettings: "Vehicle filter to configure",
  filterVehicles: "Filter",
  select: "Vehicles selection",
  dealer: "Distributor",
  client: "Client",
  account: "Account",
  group: "Group",
  all: "All",
  save: "Save",
  //Menu app drawer
  titleRep: "Summarized reports",
  titleABalance: "Comparation per period",
  titleExceptions: "In transit incidents",
  dashboard: "Overview",
  commands: "Commands",
  exit: "Exit",
  logout: "Logout",
  tracts: "ALERTRUCK",
  noName: "No name",
  accountM: "Manage",
  Users: "Users",
  entailment: "Entailment",
  //Commands

  //Dashboard
  vehiclesD: "Vehicles",
  totalFuel: "Total used fuel",
  totalRalentiFuel: "Total used fuel idle",
  exceptionsHome: "In transit incidents",
  totalDistance: "Total distance traveled",
  totalPerformance: "Average performance",
  TotalEmptyFuel: "Total fuel idle",
  Totaldistancetraveled: "Total traveled distance",
  today: "Today",
  last: "Last",
  days: "Days",
  to: "to",
  //Charts dashboard
  moreRalenti: "Fuel idle", /////
  topRalenti: "10 Topmost vehicles in fuel consumption",
  emptyFuel: "Fuel idle",
  fuel: "Fuel",
  rangeFuel: "Performance range", /////
  menorRendimiento: "Performances",
  topPerformance: "10 Lowest vehicles in fuel performance",
  rendimientoFuel: "Fuel efficiency",
  engineTime: "Engine running total time",
  drive: "Driving",
  stop: "Idle",
  ralenti: "Ralenti",
  moreTimeEngine: "Motor usage ralenti time", ///
  topEngine: "10 Topmost vehicles in motor usage",
  hour: "Hours",
  timeEngineOn: "Engine running time",
  velocityExcess: "Speeding",
  frenadoBrusco: "Sudden braking",
  excesoFreno: "Strong braking",
  aceleracionB: "Sudden acceleration",
  excessClutch: "Excess clutch",
  moreExcept: "10 Topmost in transit incidents",
  topExceptions: "10 Topmost vehicles in driving faults",
  compSemanal: "Weekly comparation",
  emtyHours: "Idle engine hours",
  driveHours: "Driving hours",
  engineOnWeek: "Engine running weekly comparation",
  distancRoad: "Traveled distance",
  distance: "Distance",
  weekDistances: "Traveled distance weekly comparation",
  rendi: "Performance",
  weekRend: "Perfomance weekly comparation",
  time: "Time",
  //Account Billings

  //Exceptions
  ExcessSpeed: "Speeding",
  BrusqueAccelerations: "Sudden acceleration cases",
  Brushesbrakes: "Sudden braking cases",
  ExcessMotorLoad: "Motor load excess",
  ExcessRPM: "RPMs in excess",
  ExcessClutch: "Clutch in excess",
  SharpTurns: "Sudden turnout",
  ExcessEngineHours: "Running motor hours in excess",
  ExcessEngineVacuum: "Idle motor hours in excess",
  NumberExceptions: "Faults quantity",

  //Session
  session: "Your session has expired",
  //AccountManager page
  addUser: "Add a new user",
  edit: "Modify",
  dateInitial: "Date of joining",
  actions: "Actions",
  active: "Active",
  inactive: "Inactive",
  deleteUser: "Are you sure to delete the selected user?",
  editUser: "Name",
  newUser: "New user",
  editUserTitle: "Modify user",
  permissions: "Not enough privileges. Contact the admin",
  selectDealer: "Select a distributor",
  //Settings
  personalization: "Customization",
  darkMode: "Dark mode",
  changeLogo: "Change logo",
  changeTheme: "Change theme",
  SystemofUnits: "Units",
  distance: "Distance",
  volume: "Volume",
  temperature: "Temperature",
  initialSettings: "Initial settings",
  //charts
  printChart: "Print chart",
  downloadPNG: "Download PNG image",
  downloadJPEG: "Download JPEG image",
  downloadPDF: "Download PDF document",
  downloadSVG: "Download SVG image",
  liters: "Liters",
  gallons: "Gallons",
  errorDashboard: "No data. Try again later",

  //Exceptions titles
  ev445: "RPMs in excess",
  ev446: "Engine off in less than X seconds",
  ev447: "Excess of clutch time while moving",
  ev448: "Excess of clutch time idle",
  ev449: "Excess of braking time",
  ev450: "Excess of motor load",
  ev451: "High ralenti percentage",
  ev452: "Sudden acceleration",
  ev453: "Sudden braking",
  ev454: "Low cruise control percentage",
  ev455: "Ralenti time alert",
  ev456: "Insufficient rest time",
  ev457: "Medium speeding",
  ev458: "Strong speeding",
  ev459: "Low fuel economy",
  ev464: "Rest detected alert",
  ev463: "End of PTO",
  ev466: "Average motor high load alert",
  ev467: "Error code detection",
  ev470: "Bus de datos desconectado",
  ev471: "Bus de datos reconectado",
  ev472: "Bus de datos erróneo seleccionado",
  ev479: "Start of consumption by PTO in motion",
  ev480: "End of consumption by PTO in motion",
  otros: "Others",

  //Offline
  offline: "No internet connection",
  online: "Internet connection",
  later: "No internet connection, try later",

  loadingData: "Loading data, this operation may take some time...",
  lateData: "The operation is taking longer than normal, please wait... ",
  loadingTable: "Loading... Please wait",

  noReport: "Please select a report",

  //Errores de servidor
  autorization: "Unauthorized user",
  error400: "An error occurred, try later",

  errorFechas:
    "The selected period must not exceed 31 days of operation. Please check...",
  selectDate: "Date selection",
  invalidDate: "Invalid date period",
  userSelect: "Select user please",

  existinguser: "User selects already exists",
  addeduser: "User added",
  userSelect: "Select user please",
  from: "From",
  to: "To",
  notExist: "User not exist",
  docNotExist: "Report not found",
  notExistData: "No data",
  addToList: "Add to the list",
  deleteVehicle: "Delete to the list",
  quickList: "Quick list",

  sizeImage: "The maximum image weight is 5 MB",
  alertImage: "The image exceeds 5 MB",
  deleteAllReports: "Are you sure to delete all selected reports?",

  invalidName: "Invalid name of report",

  //entailment
  manageoperators: "Manage operators",
  addoperator: "Add operator",
  entail: "entail",
  noEntail: "No entail",
  operator: "Operator",
  license: "License",
  type: "Type",
  licenseExpiration: "License expiration",
  address: "Address",
  registrationDate: "Registration Date",
  bonding: "Bonding",
  administration: "Administration",
  link: "Link",
  unlink: "Unlink",
  operators: "Operators",
  newOperator: "New operator",
  typeoflicense: "Type of license",
  expirationDate: "Expiration date",
  required: "Required",
  editOperator: "Edit operator",
  deleteConfirmOp: "Are you sure to delete the selected operator?",
  restrictionTravel:
    "This operator cannot be removed because you are traveling",
  restrictionMovil:
    "It is not possible to remove this operator because you have a linked mobile",
  selectMobileEquipment: "Select mobile equipment",
  other: "Other",

  //Mobiles
  manageMobileTeams: "Manage mobile teams",
  mobiles: "Mobiles",
  mobileEquipment: "Mobile equipment",
  model: "Model",
  OS: "OS",
  addEquipment: "Add equiment",
  number: "Número",
  brand: "Brand",
  operativeSystem: "Operative system",
  scanPhone: "Scan on phone to register",
  newMobileEquipment: "New mobile equipment",
  editMobileTeam: "Edit mobile team",
  deleteConfirmMo: "Are you sure to delete this mobile?",
  restrictionTravelM:
    "It is not possible to delete this mobile equipment because it is on the road",
  restrictionOperator:
    "This mobile equipment cannot be removed because it has a linked operator",
  selectAnOperator: "Select an operator",
  serialNumber: "Serial number",

  //Trafico
  traffic: "Traffic",
  inTravel: "On route",
  noTrip: "Traveling, no route",
  stopped: "Parked",
  outOfService: "Out of service",
  operatingStates: "Operating states",
  allStates: "All the states",
  vehicle: "Vehicle",
  lastPosition: "Last position",
  date: "Date",
  idleTime: "Idle time",
  statusT: "Status",
  newTrip: "New trip",
  inProgress: "In progress",
  finalize: "Finalize",
  dateT: "Date",
  assignTrip: "Assign trip",
  travels: "Travels",
  nameOfTheTrip: "Name of the trip",
  pathName: "Path name",
  origin: "Origin",
  destination: "Destination",
  start: "Start",
  finish: "Finish",
  editTrip: "Edit trip",
  map: "Map",
  updatePosition: "Update position",
  deleteTravel: "Are you sure to delete this trip?",
  finishTravel: "Are you sure to finish this trip?",
  //Carriers
  businessName: "Business name",
  serviceType: "Service type",
  modality: "Modality",
  addCarrier: "Add carrier",
  noCarrier: "Please select a carrier",
  unknown: "Unknown",
  cargoGeneral: "General cargo",
  hazardousMaterials: "Hazardous materials and waste",
  alimentaryGrade: "Alimentary grade",
  consolidated: "Consolidated",
  fullCar: "Full car",
  carrierManagement: "Carrier management",
  typeOfServices: "Type of services",
  selectContact: "Select contact",
  phone: "Phone",
  contact: "Contact",
  deleteConfirmCa: "Are you sure to remove this carrier?",
  deleteAllCarriers: "Are you sure you want to delete all selected carriers?",

  monitoring: "Monitoring",
  newCarrier: "New carrier",
  editCarrier: "Edit carrier",
  add: "Add",
  seeContacts: "See contacts",
  currentlimitTime: "The chosen time passes the current time",
  lessStartDate: "The start date must be less than the end date",
  dateRange:
    "The selected period must not exceed 7 days of operation. Please check...",
  error409Trip: "Vehicle/Driver with trip in progress",
  //DetailsAndTable
  drivingConsume: "Driving consume",
  consumptionIdle: "Consumption idle",
  consumePTO: "Consume PTO",
  motorUsage: "PTO Motor usage",
  drivingMotorUsage: "Driving motor usage",
  motorUsageIdle: "Motor usage idle",
  performance: "Performance",
  //In transit incidents details
  conduction: "Driving",
  operation: "Vehicle operation",
  logistic: "Logistic",
  diagnosticUnit: "Diagnostics",
  categories: "Categories",
  //performance
  performancePerTime: "Period performance",
  tripEfficiency: "Trip efficiency",
  location: "Location",
  event: "Event",
  eventDetails: "Event details",
  exceptionsReport: "In transit incidents report",
  calGlobal: "Global calculations",
  efficiencyAnalisis: "Efficiency analysis",
  maxSpeed: "Maximum speed",
  avgSpeed: "Average speed",
  rpmMax: "Maximum Rpm",
  rpmAvg: "Average Rpm",
  breakTime: "Break time",
  tripTime: "Trip time",
  motorUsag: "Time engine usage",
  expDash: "Export dashboard",
  expExcel: "Export excel",
  odometer: "Odometer",
  motorEfficiency: "Motor efficiency analysis",
  velProm: "Average vehicle speed",
  velPromRPM: "Average engine speed in Rpm",
  cargaProm: "Average motor load percentage",
  pedalProm: "Average accelerator pedal position",
  vel: "Speed",
  percent: "Percent",
  consult: "Consult",
  compare: "Compare",
  FuelConsumed: "Fuel consumed",
  EngineUsageTime: "Engine usage time",
  BadHabits: "Bad driving habits detected",
  FuelControlCrucero: "Cruise mode fuel",
  TimeControlCrucero: "Cruise mode time",
  DistanceControlCrucero: "Cruise mode distance",
  cruiseActive: "Active crontrol cruise",
  cruiseInactive: "Inactive control cruise",
  BrakeCounter: "Brake",
  ClutchCounter: "Clutch excess",
  RoughBrakeCounter: "Brake excess",
  ExcessRpmCounter: "RPM excess",
  notAvailable: "Not available",
  threshold: "Threshold",
  ptoElapsed: "PTO time elapsed",
  engineShutOff: "Time the engine was shut off",
  clutchTime: "Clutch time",
  thresholdSpeed: "Threshold speed",
  thresholdTime: "Threshold time",
  brakeTime: "Brake time",
  initialSpeed: "Initial speed",
  finalSpeed: "Final speed",
  excessLoadT: "Excess load time",
  loadMax: "Maximum load",
  umbralPercentage: "Threshold percentage",
  ralentiTime: "Ralenti time",
  ralentiF: "Ralenti fuel",
  accelerationMax: "Maximum acceleration detected",
  decelerationMax: "Maximum deceleration",
  percentageCC: "Cruise control percentage",
  fuelEconomy: "Fuel economy",
  timeAt: "Time at",
  initialTotFuel: "Initial total fuel",
  avgLoadPercentage: "Average load percentage detected",
  thresholdAvgLoadP: "Average load percentage threshold",
  initialFuelIdle: "Initial fuel idle",
  frequency: "Frencuency",
  busReal: "Bus de datos correcto",
  selectedBus: "Bus de datos seleccionado",
  //comparative
  comparative: "Comparative",
  efficiency: "Efficiency",
  tripAnalysis: "Efficiency analysis by trips",
  tripInTransitIncidents: "In transit incidents by trips",
  distribution: "Distribution of in transit incidents",
  trip: "Trip",
  timeEngine: "Engine usage time",
  fuelConsumed: "Fuel consumed",
  tripDuration: "Trip duration",
  minSpeed: "Min speed",
  minRpm: "Min RPM",
  maxRpmm: "Max RPM",
  AvgMotorLoad: "Average motor load",
  noTrips: "Period without assigned trip",
  noTripSelected: "Please select a trip",
  fuelConsum: "Fuel consume",
  vol: "Volume",
  finalOdometer: "Final odometer",
  finalFuel: "Final Fuel",
  excededData: "The information requested is to much for the local storage",
  PerformancePerHour: "Performance per hour",
  DrivingPerformance: "Driving Performance",
  maxSpeedDate: "Max speed date",
  lat: "Lattitude",
  lon: "Longitude",
  descripcion: "Description",
  engineHistory: "Engine usage history",
  tripsHistory: "Trips history",
  //individual fuel
  individualFuel: "Individual fuel",
  selectVehicle: "Select vehicle",
  update: "Update",
  newFrom: "From",
  newTo: "To",
  recentActivity: "Recent activity",
  magnitudeOfChange: "Magnitude of change",
  selectPeriod: "Select period",
  seeMore: "See more",
  noVehicle: "No vehicle selected",
  locationUnavailable: "Location unavailable",
  noDataFP: "There is no data for performance and recent activity",
  noDataRL:
    "There is no data for the last level of fuel reported by the vehicle selected",
  legendPerformance: "Average performance",
  noResultsFound: "No results found for the given location",
  geoFailed: "The reverse geocoding failed due to: ",
  loadingLocation: "Loading location... Please wait",
  noDetailsData: "There is no data available",
  //descripcion de eventos
  distanceTraveled: "Distance traveled during the event",
  timeElapsed: "Time elapsed during the event",
  maxVal: "Maximum value detected",
  minTime: "Minimum shutdown time",
  detectedShutdown: "Detected shutdown time",
  timeDetected: "Time detected",
  speedAtTheStart: "Speed at the start of the event",
  speedAtTheEnd: "Speed at the end of the event",
  thresholdLoadP: "Threshold load percentage",
  percentageOfMaxLoad: "Percentage of maximum load detected",
  tripThresholdTime: "Trip threshold time",
  idleTimeElapsed: "Idle time elapsed since the start of the trip",
  fuelConsumedIdleMark: "Fuel consumed at idle from the mark",
  thresholdAcceleration: "Threshold acceleration",
  thresholdBraking: "Threshold braking",
  maxBrakingD: "Maximum braking detected",
  percentageDetected: "Percentage detected",
  drivingThresholdTime: "Driving threshold time",
  drivingTimeElapsed: "Driving time elapsed",
  minPerformance: "Minimum performance",
  performanceDetected: "Performance detected",
  totalFuelConsumption: "Total fuel consumption",
  fuelConsumptionIdle: "Fuel consumption idle",
  thresholdAvgLoad: "Threshold average load",
  //rastreo
  tracking: "Tracking",
  timeWT: "Time without transmitting",
  checkStatus: "Check status",
  centerOnMap: "Center on map",
  sendCommand: "Send command",
  seeAct: "See activity",
  seeStatus: "See status",
  loadingTextForTable: "Loading... Please wait",
  addressNotAvailable: "Address not available",
  plates: "Plates",
  year: "Year",
  color: "Color",
  category: "Category",
  comments: "Comments",
  operatingState: "Operating state",
  gps: "GPS",
  server: "Server",
  position: "Position",
  battery: "Battery",
  gsm: "GSM",
  realPosition: "Real position",
  connectedToServer: "Connected to server",
  autoReports: "Auto reports",
  switch: "Ignition switch",
  ignitionLocked: "Ignition locked",
  panicButton: "Panic button",
  engineLocked: "Engine locked",
  supportButton: "Support button",
  lightSiren: "Lights/Siren",
  helpButton: "Help button",
  doorsLocked: "Doors locked",
  batteryConnected: "Battery connected",
  doorsUnlocked: "Doors unlocked",
  activity: "Activity",
  engine: "Engine",
  connection: "Connection",
  geoReference: "Geographic reference",
  iO: "Inputs/Outputs",
  vehAttributes: "Vehicle attributes",
  reports: "Reports",
  preferences: "Preferences",
  confirmedCommand: "Confirmed command",
  confirmEngineLock: "Confirm engine lock",
  lastTransmission: "Last transmission",
  onGoing: "On going",
  stopped: "Stopped",
  noteVehState: "Note: It was moving in the same place",
  valueNotAvailable: "Value not available at the moment",
  distanceNotAvailable: "Distance not available at the moment",
  durationNotAvailable: "Duration not available at the moment",
  fuelNotAvailable: "Fuel not available at the moment",
  since: "Since",
  noResponse: "No response",
  altavozOn: "Activate speaker",
  altvozOff: "Deactivate speaker",
  callOn: "Activate call",
  tmReportsOn: "Activate reports (TM-FIL-DIST)",
  tmReportsOff: "Deactivate reports (TM-FIL-DIST)",
  reportVel: "Report for speeding",
  reportIgnicion: "Ignition switch report",
  repStartStop: "Report Stopped / Running",
  repGpsState: "Gps status report",
  getImei: "Check IMEI",
  getSimData: "Check SIM Card data",
  lockEngine: "Lock engine",
  unlockEngine: "Unlock engine",
  resetES: "Restore E/S",
  resetButtons: "Restore buttons",
  freeText: "Free text",
  confirmCommand: "Confirm send command",
  noActivityData: "No data for the selected period",
  selectCommand: "Select a command",
  //HOME
  firmwareManager: "Firmware Manager",
  firmware: "Firmware",
  manager: "Manager",
  bosonUser: "Boson user",
  master: "Master",
  updateConfig: "Update configuration",
  upd: "Update",
  config: "Configuration",
  export: "Export",
  selectFile: "Select file",
  uploadFile: "Upload file",
  selectFirmwareV: "Select a firmware version",
  chooseFile: "Choose file",
  noFileSelected: "No file selected",
  fileName: "File name",
  enterFileName: "Enter the file name for registration",
  firmwareVersion: "Firmware version",
  dateOfLastUpdate: "Date of last update",
  inprocess: "Working",
  requiredName: "Required name",
  requiredFile: "Required file",
  confirmation: "Confirmation",
  continueUpdate: "Do you want to continue the update?",
  theFollowingVeh: "The following vehicles will update with version",
  selectAtLeasOnetVeh: "Please select at leat one vehicle",
  versionRequired: "Version required",
  cancelUpdate: "Do you want to cancel the update?",
  yes: "Yes",
  no: "No",
  details: "Details",
  masterFunction: "Function not available for the selected level",
  requestedBy: "Requested by",
  updParameters: "Update parameters",
  securityCode: "Security code",
  maxWaitingTimePerCommand: "Maximum waiting time per command",
  timeInSec: "Time in seconds",
  NumOfAttempsPerCommand: "Number of attemps per command",
  NumOfAttemps: "Number of attemps",
  selectFirmwareVersionFromDevice: "Select a firmware version from your device",
  addNew: "Add new",
  statusDate: "Status date",
  processTime: "Process time",
  operationInProgress: "Operation in progress",
  sendingUpd: "Sending update...",
  updSent: "Update sent, check section 'In progress'.",
  visit: "Visit",
  deleteFirmwareVersionConfirm: "Are you sure you want to delete this firmware version",
  updParametersNote: "Note: Currently the update is only available for devices TRK7, Phoenix 4G and MTT.",
  updParametersNote2: "Note: Currently the update is only available for devices PC41.",
  deviceOnline: "On line",
  checkVersion: "Please check that the version selected correspond with the ",
  replaceVersion: "The file already exists, ¿do you want to replace it?",
  addFirmwareVersionConfirm: "¿Do you want to add this firmware version?",
  versionDeletedWithSuccess: "Firmware version deleted with success",
  versionAddedWithSuccess: "Firmware version added with success",
  addingVersion: "Adding version",

  //States of FW devices
  updated: "Update",
  pending: "Pending",
  unknown: "Unknown",
  isInProgress: "In progress",
  canceled: "Canceled",
  failed: "Failed",

  //ORDERS LIST
  orders: "Orders",
  ordersList: "Orders list",
  retry: "Retry",
  seeDetails: "See details",
  modify: "Modify",
  orderId: "Order Id",
  failedDevices: "Failed devices",
  order: "Order",
  confirmRetryOrder: "Are you sure you want to retry the order?",
  confirmModifyOrder: "Are you sure you want to modify the order?",
  confirmDeleteOrder: "Are you sure you want to delete the order?",
};

export default en;
