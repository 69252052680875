<template>
  <div v-resize="Resize" id="app">
    <v-app>
      <template v-if="this.$store.state.authenticated">
        <router-view @authenticated="setAuthenticated" />
      </template>
      <template v-else>
        <router-view @authenticated="setAuthenticated" /> </template
    ></v-app>
    <v-snackbar
      v-model="snackbar"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="true"
      :vertical="mode === 'vertical'"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn :color="colorTheme" text @click="snackbar = false" v-bind="attrs">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "App",
  data: () => ({
    //
  }),
  created() {
    this.$store.commit("setValuesLanguage");
  },
  beforeUpdate: function () {
    var vm = this;
    //console.log(vm);
  },
  updated: function() {
    var vm = this;
    this.$nextTick(function() {
      var auth = JSON.parse(localStorage.getItem("auth")) || false;

      if (vm.$route.path === "/" || vm.$route.path == "") {
        if (auth == true) {
          if (vm.$route.path !== "/home") {
            vm.$router.replace({ name: "home" });
          }
        } else {
          vm.$store.commit("auth", false);
          if (vm.$route.path !== "/login") {
            vm.$router.replace({ name: "login" });
          }
        }
      } else {
        if (auth == true && vm.$route.path == "/login") {
          if (vm.$route.path !== "/home") {
            vm.$router.replace({ name: "home" });
          }
        } else if (auth == false) {
          vm.$store.commit("auth", false);
          // vm.$router.replace({ name: "login" });
        }
      }
    });
  },
  computed: {
    ...mapState([
      "sessionexp",
      "text",
      "mode",
      "timeout",
      "x",
      "y",
      "colorTheme",
      "settings",
      "initialSettings",
      "settingsTitle",
      "disableFV",
      "calendarTime",
    ]),
    snackbar: {
      get: function() {
        return this.$store.state.snackbar;
      },
      set: function(value) {
        this.$store.commit("snackbar", false, "");
      },
    },
  },
  mounted() {
    let vm = this;
    
    vm.Resize();
   // this.$store.dispatch("ConnectRabbitMQ");

    //se establecen listeners cuando haya eventos de cambio de la conexión a internet
    window.addEventListener('offline', function (e) {
      vm.handleConnectivityChange(false);
    });

    window.addEventListener('online', function (e) {
      vm.handleConnectivityChange(true);
    });

    //se obtiene la conexión a internet al cargar la web
    vm.handleConnectivityChange(navigator.onLine);
  },
  methods: {
    handleConnectivityChange(status) {
      this.$store.dispatch("handleConnectivityChange", status);
    },
    Resize() {
      this.$store.commit("onResize");
    },
    setAuthenticated(status) {
      this.$store.commit("auth", status);
    },
  },
};
</script>
