import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
import Vuex from "vuex";
import VueAxios from "vue-axios";

Vue.use(Router);
Vue.use(VueAxios, axios);
Vue.use(Vuex);

export default new Router({
  routes: [
    {
      path: "*",
      meta: {
        public: true,
      },
    },
    {
      path: "/",
      name: "Default",
      component: () => import("@/components/login"),
      meta: {
        public: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/components/login"),
      meta: {
        public: true,
      },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/components/home"),
    },
    {
      path: "/home/list",
      name: "list",
      component: () => import("@/components/list"),
    },
  ],
});
