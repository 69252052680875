import i18n from "@/plugins/i18n";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import moment from "moment";
import "moment/locale/es";
import logoBoson from "@/assets/LogoBosonWhite.png";

export default {
  onResize: (state) =>
  {
    state.windowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    //Mobile view
    if (state.windowSize.width < 824)
    {
      state.mobileView = true;
      state.dealerAttach = ".dealer";
      state.clientAttach = ".client";
      state.firmwareVersion = ".firmwareVersion";
    } else
    {
      state.mobileView = false;
      state.dealerAttach = false;
      state.clientAttach = false;
      state.firmwareVersionAttach = false;
    }
  },
  setValuesLanguage: (state) =>
  {
    localStorage.setItem("language", localStorage.language == undefined ? "es" : localStorage.language);

    if (localStorage.getItem("language") == "en")
    {
      moment.updateLocale('en', {
        meridiem: function (hour, minute, isLowercase)
        {
          if (hour >= 12)
            return isLowercase ? 'p.m.' : 'PM';
          else
            return isLowercase ? 'a.m.' : 'AM';
        }
      });
    } else
    {
      //Se actualiza el locale para los meses cortos ya que originalmente son en minúscula y con punto, ejemplo: ago. y debe aparecer "Ago"
      var mesesCortos = 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_');
      moment.updateLocale('es', {
        monthsShort: mesesCortos,
        meridiem: function (hour, minute, isLowercase)
        {
          if (hour >= 12)
            return isLowercase ? 'p.m.' : 'PM';
          else
            return isLowercase ? 'a.m.' : 'AM';
        }
      });
    }

    state.loginBtn = i18n.t("access").toUpperCase();
    state.headersVersion = [
      { text: i18n.t("firmwareVersion").toUpperCase(), value: 'Version', align: 'center' },
    ];
    state.headersFirmwareUpdate = [
      { text: '', align: 'start', sortable: false },
      { text: i18n.t("deviceOnline").toUpperCase(), value: 'isOnline', align: "center" },
      { text: i18n.t("vehicle").toUpperCase(), value: 'MovilId', align: "center" },
      { text: i18n.t("lastTransmission").toUpperCase(), value: 'lastTransmission', align: "center" },
      { text: i18n.t("firmwareVersion").toUpperCase(), value: 'FirmwareVersion', align: "center" },
      { text: i18n.t("dateOfLastUpdate").toUpperCase(), value: 'UpdateDate', align: "center" },
      { text: i18n.t("status").toUpperCase(), value: 'State', align: "center" },
      { text: i18n.t("details").toUpperCase(), value: 'Notes', align: "center" },
      { text: i18n.t("requestedBy").toUpperCase(), value: 'RequestedBy', align: "center" },
      { text: i18n.t("actions").toUpperCase(), value: 'act', align: "center", sortable: false },
    ];
    state.infoVeh = [];
    state.fileNameRules = [
      v => !!v || i18n.t("requiredName")
    ];

    //Para tiempo en proceso se restara la UpdateDate de la fecha actual
    state.headersDevicesStatus = [
      { text: i18n.t("vehicle").toUpperCase(), value: 'DeviceId', align: "center" },
      { text: i18n.t("dealer").toUpperCase(), value: 'DealerName', align: "center" },
      { text: i18n.t("status").toUpperCase(), value: 'Status', align: "center" },
      { text: i18n.t("statusDate").toUpperCase(), value: 'UpdateDate', align: "center" },
      { text: i18n.t("processTime").toUpperCase(), value: 'processTime', align: "center" }
      //{ text: i18n.t("actions").toUpperCase(), value: 'actions', align: "center", sortable: false },
    ];

    state.headersList = [
      { text: i18n.t("orderId").toUpperCase(), value: 'Id', align: "center" },
      { text: i18n.t("dateT").toUpperCase(), value: 'OrderDate', align: "center" },
      { text: i18n.t("requestedBy").toUpperCase(), value: 'RequestedBy', align: "center" },
      { text: i18n.t("status").toUpperCase(), value: 'Status', align: "center" },
      { text: i18n.t("processTime").toUpperCase(), value: 'processTime', align: "center" },
      { text: i18n.t("failedDevices").toUpperCase(), value: 'FailedDevices', align: "center" },
      { text: i18n.t("actions").toUpperCase(), value: 'actions', align: "center", sortable: false },
    ];

    state.headerOrderDetails = [
      { text: i18n.t("vehicle").toUpperCase(), value: 'DeviceId', align: "center" },
      { text: i18n.t("dealer").toUpperCase(), value: 'DealerName', align: "center" },
      { text: i18n.t("status").toUpperCase(), value: 'Status', align: "center" },
      { text: i18n.t("statusDate").toUpperCase(), value: 'UpdateDate', align: "center" },
      { text: i18n.t("processTime").toUpperCase(), value: 'processTime', align: "center" }
    ];
  },
  userEntry: (state, user) =>
  {
    state.username = user;
  },
  passEntry: (state, pass) =>
  {
    state.password = pass;
  },
  progresslogin: (state, payload) =>
  {
    state.progresslogin = payload;
  },
  loginBtn: (state, payload) =>
  {
    state.loginBtn = payload;
  },
  loginOffline: (state, value) =>
  {
    state.loginOffline = value;
  },
  selectedAccessEntry: (state, access) =>
  {
    var AccessSelect = access;
    state.multipleUserData.forEach((element) =>
    {
      if (element.AccessId == AccessSelect.AccessId)
      {
        state.SelectedAccess = element;
        localStorage.setItem(
          "SelectedAccess",
          JSON.stringify(state.SelectedAccess)
        );

        localStorage.setItem(
          "UserDataAccessList",
          JSON.stringify(state.SelectedAccess)
        );
      }
    });
  },
  selectedAccessValue: (state, value) =>
  {
    state.selectedVal = value;
  },
  responseLogin: (state, userData) =>
  {
    state.userData = userData;
    localStorage.setItem("userData", JSON.stringify(state.userData));
    if (userData.UserAccessList.length > 1)
    {
      state.multipleUserData = userData.UserAccessList;
      state.multipleAccess = true;
    } else
    {
      state.multipleAccess = false;
    }
  },
  getToken: (state, payload) =>
  {
    localStorage.setItem("auth", true);
    state.authenticated = true;
    state.tokenInfo = payload[0];
    state.SelectedAccess = payload[1];
    localStorage.setItem(
      "SelectedAccess",
      JSON.stringify(state.SelectedAccess)
    );
    state.token = payload[0].access_token;
    localStorage.setItem("Token", state.token);
    state.refreshToken = payload[0].refresh_token;
  },
  auth: (state, payload) =>
  {
    localStorage.setItem("auth", payload);
    state.authenticated = payload;
    if (!payload)
    {
      state.dealerSelected = {};
      state.clientSelected = {};
      state.accountSelected = {};
      state.groupSelected = {};
      if (router.app.$route.path !== "/login")
      {
        router.replace({ name: "login" });
      }
      localStorage.setItem("start", false);
    }
  },
  cancelMultipleAccess: (state) =>
  {
    state.multipleAccess = false;
  },
  rememberMe: (state, value) =>
  {
    state.remember = value;
  },
  invalidUser: (state, value) =>
  {
    state.invalidUser = value;
  },
  snackbar: (state, payload) =>
  {
    state.snackbar = payload.snackbar;
    state.text = payload.text;
    if (payload.x != undefined || payload.x != null)
    {
      state.x = payload.x;
    } else
    {
      state.x = null;
    }
  },
  mouseLeave: (state, val) =>
  {
    if (val == "es")
    {
      if (!vuetify.framework.theme.dark)
      {
        if (i18n.locale == "es")
        {
          state.hoveres = "color: " + state.colorTheme;
        } else
        {
          state.hoveres = "color: black ";
        }
      } else
      {
        if (i18n.locale == "es")
        {
          state.hoveres = "color: " + state.colorTheme;
        } else
        {
          state.hoveres = "color: white ";
        }
      }
    } else
    {
      if (!vuetify.framework.theme.dark)
      {
        if (i18n.locale == "en")
        {
          state.hoveren = "color: " + state.colorTheme;
        } else
        {
          state.hoveren = "color: black ";
        }
      } else
      {
        if (i18n.locale == "en")
        {
          state.hoveren = "color: " + state.colorTheme;
        } else
        {
          state.hoveren = "color: white ";
        }
      }
    }
  },
  mouseover: (state, val) =>
  {
    if (val == "es") state.hoveres = "color: " + state.colorTheme;
    else state.hoveren = "color: " + state.colorTheme;
  },
  defineLangColor: (state, val) =>
  {
    if (!vuetify.framework.theme.dark)
    {
      if (i18n.locale == "es")
      {
        state.hoveres = "color: " + state.colorTheme;
        state.hoveren = "color: black ";
      } else
      {
        state.hoveres = "color: black ";
        state.hoveren = "color: " + state.colorTheme;
      }
    } else
    {
      if (i18n.locale == "es")
      {
        state.hoveres = "color: " + state.colorTheme;
        state.hoveren = "color: white ";
      } else
      {
        state.hoveres = "color: white ";
        state.hoveren = "color: " + state.colorTheme;
      }
    }
  },
  fillTable: (state, val) =>
  {
    // state.infoVeh = [];
    // console.log('locale: ', i18n.locale);
    // if (val.length > 0) {
    //   val.forEach((info, index) => {
    //     var status, checkingStatus;
    //     if (info.Status == 'Actualizado' || info.Status == i18n.t("updated").toUpperCase()) {
    //       status = i18n.t("updated").toUpperCase();
    //       checkingStatus = true;
    //     } else if (info.Status == 'Pendiente' || info.Status == i18n.t("pending").toUpperCase()) {
    //       status = i18n.t("pending").toUpperCase();
    //       checkingStatus = false;
    //     } else {
    //       status = i18n.t("inprocess").toUpperCase();
    //       checkingStatus = false;
    //     }

    //     if (i18n.locale == "en") {
    //       var myDate = moment(info.DateLastUpdate).format("DD/MM/YYYY HH:mm");
    //     } else {
    //       var myDate = moment(info.DateLastUpdate).format("MM/DD/YYYY HH:mm");
    //     }
    //     var item = {
    //       DateLastUpdate: myDate,
    //       FirmwareVersion: info.FirmwareVersion,
    //       Status: status,
    //       Vehicle: info.Vehicle,
    //       // isSelectable: checkingStatus
    //     };
    //     state.infoVeh.push(item);
    //   });
    // }
    // state.progressPrincipalTable = false;
    //state.fullResponse = val;
  },
  loadingPT: (state, val) =>
  {
    state.progressPrincipalTable = val;
  },
  loadingVT: (state, val) =>
  {
    state.progressVersionTable = val;
  },
  fillVersionTable: (state, val) =>
  {
    if (state.isMaster)
    {
      state.itemsVersion = val;
    } else
    {
      state.itemsVersion = [state.currentFirmwareFile];
    }

    //state.progressVersionTable = false;
    state.progressForUpdParameters = false;
  },
  setDealers: (state, val) =>
  {
    state.dealerItems = val;
    localStorage.setItem("dealerItems", JSON.stringify(val));
    //state.dealerItems.splice(0, 0, i18n.t("all"));
    if (val.length == 0) state.dealerItems = [i18n.t("noDetailsData")];
    state.dealerSelected = state.dealerItems[0];
  },
  setClients: (state, val) =>
  {
    state.clientItems = val;
    if (val.length > 0)
    {
      state.clientItems.splice(0, 0, i18n.t("all"));
    } else
    {
      state.clientItems = [i18n.t("all")];
    }
    state.clientSelected = state.clientItems[0];
  },
  setDealerAccount: (state, val) =>
  {
    //Se selecciona un dealer 
    state.dealerSelected = val;
    //Se establecen los clientes como TODOS
    state.clientSelected = i18n.t("all");
    //Se establece en localStorage el DealerSelectedId
    localStorage.setItem("DealerSelectedId", JSON.stringify(state.dealerSelected));
  },
  setMasterFunctions: (state, val) =>
  {
    state.isMaster = val;
  },
  setClientAccount: (state, val) =>
  {
    //Se establece el clientId
    state.clientSelected = val;
    //Se establece en localStorage el ClientSelectedId
    localStorage.setItem("ClientSelectedId", JSON.stringify(state.clientSelected));
  },
  setFirmwareDevicesList: (state, val) =>
  {
    state.infoVeh = val;
  },
  setFirmwareMttDevicesList: (state, val) =>
  {
    state.infoVehMtt = val;
  },
  fillDevicesStatusTable: (state, val) =>
  {
    state.itemsDevicesStatus = [];
    const dealerSelected = JSON.parse(localStorage.getItem("DealerSelectedId"));
    const selectedAccess = JSON.parse(localStorage.getItem("SelectedAccess"));
    let lang = localStorage.getItem("language");

    val.forEach((vehicle, index) =>
    {
      //Si el user es master, se muestran todos los equipos actualizandose
      if (selectedAccess.Level == "Master")
      {
        //Para Fecha de estatus se le da formato a fecha
        let datefinalToMs = moment(vehicle.UpdateDate).valueOf();
        vehicle.UpdateDate.replace("T", " ");

        let datefinal = "";
        //Se obtiene obj de momentjs con el formato de fecha e indicando que es utc
        let dateFormat = moment.utc(vehicle.UpdateDate, "YYYY-MM-DD HH:mm");

        /*Se obtienen millisegundos de obj de momentjs en utc convertido a local, 
        ya que si se obtienen de datefinal en español no reconoce la abreviación del mes*/
        let updateDateFormattedInMilliseconds = moment(dateFormat.local()).valueOf();

        if (dateFormat === "Invalid date")
        {
          vehicle.UpdateDate = vehicle.UpdateDate;
        } else
        {
          //Se indica que queremos la fecha local y el formato adecuado para español o inglés
          if (lang.toUpperCase() == "ES")
          {
            datefinal = dateFormat.local().format("DD/MMM/YYYY hh:mm A");
          } else
          {
            datefinal = dateFormat.local().format("MMM/DD/YYYY hh:mm A");
          }
        }

        //Para Tiempo en proceso se resta la hora actual de la fecha de estatus
        let todayInMilliseconds = moment().valueOf();
        //console.log(todayInMilliseconds);

        let processTime = todayInMilliseconds - updateDateFormattedInMilliseconds;
        //console.log(processTime);

        let t = processTime;
        let h = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let m = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));

        if (m < 10)
        {
          m = "0" + m;
        }

        processTime = h + "h " + m + "m";
        //console.log(processTime);

        //Se obtiene el dealerName a partir de su ID
        const dealersList = JSON.parse(localStorage.getItem("dealerItems"));
        let dealerName = dealersList.find(dealer => dealer.DealerId === vehicle.DealerId).Name;

        var item = {};
        item = {
          DeviceId: vehicle.DeviceId,
          DealerName: dealerName,
          Status: vehicle.Status,
          UpdateDate: datefinal,
          UpdateDateToMs: datefinalToMs,
          processTime: processTime,
        };
        state.itemsDevicesStatus.push(item);

      } else
      {
        //Si no es master, solo se muestran los equipos del dealer seleccionado
        if (vehicle.DealerId == dealerSelected.DealerId)
        {
          //Para Fecha de estatus se le da formato a fecha
          let datefinalToMs = moment(vehicle.UpdateDate).valueOf();
          vehicle.UpdateDate.replace("T", " ");

          let datefinal = "";
          let lang = localStorage.getItem("language");
          //Se obtiene obj de momentjs con el formato de fecha e indicando que es utc
          let dateFormat = moment.utc(vehicle.UpdateDate, "YYYY-MM-DD HH:mm");

          /*Se obtienen millisegundos de obj de momentjs en utc convertido a local, 
          ya que si se obtienen de datefinal en español no reconoce la abreviación del mes*/
          let updateDateFormattedInMilliseconds = moment(dateFormat.local()).valueOf();

          if (dateFormat === "Invalid date")
          {
            vehicle.UpdateDate = vehicle.UpdateDate;
          } else
          {
            //Se indica que queremos la fecha local y el formato adecuado para español o inglés
            if (lang.toUpperCase() == "ES")
            {
              datefinal = dateFormat.local().format("DD/MMM/YYYY hh:mm A");
            } else
            {
              datefinal = dateFormat.local().format("MMM/DD/YYYY hh:mm A");
            }
          }

          //Para Tiempo en proceso se resta la hora actual de la fecha de estatus
          let todayInMilliseconds = moment().valueOf();
          //console.log(todayInMilliseconds);

          let processTime = todayInMilliseconds - updateDateFormattedInMilliseconds;
          //console.log(processTime);

          let t = processTime;
          let h = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          let m = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));

          if (m < 10)
          {
            m = "0" + m;
          }

          processTime = h + "h " + m + "m";
          //console.log(processTime);

          //Se obtiene el dealerName a partir de su ID
          const dealersList = JSON.parse(localStorage.getItem("dealerItems"));
          let dealerName = dealersList.find(dealer => dealer.DealerId === vehicle.DealerId).Name;

          var item = {};
          item = {
            DeviceId: vehicle.DeviceId,
            DealerName: dealerName,
            Status: vehicle.Status,
            UpdateDate: datefinal,
            UpdateDateToMs: datefinalToMs,
            processTime: processTime,
          };
          state.itemsDevicesStatus.push(item);
        }
      }
    });
    state.progressForVehInProgress = false;
  },
  fillListTable: (state, val) =>
  {
    state.itemsList = [];

    let lang = localStorage.getItem("language");
    val.forEach((order, index) =>
    {
      order.OrderDate.replace("T", " ");
      let dateFormat = moment.utc(order.OrderDate, "YYYY-MM-DD HH:mm");
      let datefinal = "";
      if (dateFormat === "Invalid date")
      {
        order.OrderDate = order.OrderDate;
      } else
      {
        //Se indica que queremos la fecha local y el formato adecuado para español o inglés
        if (lang.toUpperCase() == "ES")
        {
          datefinal = dateFormat.local().format("DD/MMM/YYYY hh:mm A");
        } else
        {
          datefinal = dateFormat.local().format("MMM/DD/YYYY hh:mm A");
        }
      }

      let updateDateFormattedInMilliseconds = moment(dateFormat.local()).valueOf();

      //Para Tiempo en proceso se resta la hora actual de la fecha de estatus
      let todayInMilliseconds = moment().valueOf();

      let processTime = todayInMilliseconds - updateDateFormattedInMilliseconds;

      let t = processTime;
      let h = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let m = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));

      if (m < 10)
      {
        m = "0" + m;
      }

      processTime = h + "h " + m + "m";

      let isSelectable = true;
      if (order.Status == i18n.t("inprocess").toUpperCase() || order.Status == i18n.t("pending").toUpperCase()) isSelectable = false;

      var item = {};
      item = {
        Id: order.Id,
        OrderDate: datefinal,
        RequestedBy: order.RequestedBy,
        Status: order.Status,
        processTime: processTime,
        FailedDevices: order.FailedDevices,
        isSelectable: isSelectable
      };
      state.itemsList.push(item);

    });
    state.progressForList = false;
  },
  fillOrderDetailsTable: (state, val) =>
  {
    state.itemsOrderDetails = [];
    const dealerSelected = JSON.parse(localStorage.getItem("DealerSelectedId"));
    let lang = localStorage.getItem("language");

    val.forEach((details, index) =>
    {
      details.UpdateDate.replace("T", " ");
      let datefinal = "";
      let dateFormat = moment.utc(details.UpdateDate, "YYYY-MM-DD HH:mm");
      let updateDateFormattedInMilliseconds = moment(dateFormat.local()).valueOf();


      if (dateFormat === "Invalid date")
      {
        details.UpdateDate = details.UpdateDate;
      } else
      {
        //Se indica que queremos la fecha local y el formato adecuado para español o inglés
        if (lang.toUpperCase() == "ES")
        {
          datefinal = dateFormat.local().format("DD/MMM/YYYY hh:mm A");
        } else
        {
          datefinal = dateFormat.local().format("MMM/DD/YYYY hh:mm A");
        }
      }

      let todayInMilliseconds = moment().valueOf();
      let processTime = todayInMilliseconds - updateDateFormattedInMilliseconds;
      let t = processTime;
      let h = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let m = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));

      if (m < 10)
      {
        m = "0" + m;
      }

      processTime = h + "h " + m + "m";

      //Se obtiene el dealerName a partir de su ID
      const dealersList = JSON.parse(localStorage.getItem("dealerItems"));
      let dealerName = dealersList.find(dealer => dealer.DealerId === details.DealerId).Name;

      var item = {};
      item = {
        DeviceId: details.DeviceId,
        DealerName: dealerName,
        Status: details.Status,
        UpdateDate: datefinal,
        processTime: processTime,
      };
      state.itemsOrderDetails.push(item);

    });


    state.progressForOrderDetails = false;
  },
  openDialogSendingUpdate: (state, val) =>
  {
    state.sendingUpdate = val;
  },
  changeTextInDialogSendingUpdate: (state, val) =>
  {
    setTimeout(() =>
    {
      state.textDialog = i18n.t("updSent");
    }, 1000);
  },
};

//mocky io response
// https://run.mocky.io/v3/8b0e2daf-75d2-4efd-ae6b-e9861199d4c9