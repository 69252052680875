import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./registerServiceWorker"
import vuetify from "./plugins/vuetify"
import i18n from "./plugins/i18n"
import VueWoker from "vue-worker"
import moment from "moment"

Vue.prototype.moment = moment
Vue.config.productionTip = true
Vue.use(moment)
Vue.use(VueWoker, "$WebWorker")

new Vue({
  el: "#app",
  router,
  store,
  i18n,
  components: { App },
  template: "<App/>",
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
