const es = {
  //Commons
  cancel: "Cancelar",
  search: "Buscar",
  accept: "Aceptar",
  continue: "Continuar",
  back: "Atras",
  noData: "Sin datos",
  apply: "Aplicar",
  notice: "Aviso",
  close: "Cerrar",
  //Login
  login: "Iniciar sesión",
  loginCard: "Ingresar",
  selectUser: "Seleccionar",
  user: "Usuario",
  pass: "Contraseña",
  spa: "Español",
  eng: "Inglés",
  access: "ACCEDER",
  rights: "Todos los derechos reservados.",
  level: "Nivel",
  ref: "Referencia",
  status: "Estatus",
  rows: "Filas por página",
  invalidUs: "Datos inválidos",
  invalidPass: "Contraseña inválida",
  invalidEmail: "Ingrese un correo válido",
  tryAgain: "Usuario no válido, intente de nuevo",
  loginErr: "No se pudo establecer la conexión, intente mas tarde",
  rememberme: "Recordar mi cuenta",
  invalidUser: "No cuenta con el permiso para usar esta aplicación.",
  subheader: "Accesos disponibles",
  noSelection: "Sin selección",
  of: "de",
  warning: "Advertencia",

  //Reports
  deleteConfirm: "¿Está seguro de eliminar este reporte?",
  inprocess: "En proceso",
  delete: "Eliminar",
  download: "Descargar",
  new: "Nuevo reporte",
  name: "Nombre",
  date: "Fecha de creación",
  col: "Columnas",
  vehicles: "Vehículos",
  type: "Tipo de reporte",
  periodTitle: "Periodo",
  selectAll: "Seleccionar todos",
  available: "Vehículos disponibles",
  noResults: "Sin resultados para la búsqueda",
  asist: "Asistente de nuevo reporte",
  commandSetting: "Configuración de comandos",
  titleDes: "Reporte de desempeño",
  period: "Periodo del reporte",
  selectV: "Vehículos seleccionados",
  sendEmail: "Enviar a correo electrónico",
  selCols: "Seleccionar columnas disponibles",
  startDate: "Fecha de inicio",
  endDate: "Fecha de fin",
  type2: "Tipo de reporte",
  distance: "Distancia total",
  usedfuel: "Combustible usado",
  drivefuel: "Combustible de manejo",
  ralentifuel: "Combustible en vacío",
  rendbruto: "Rendimiento bruto",
  consumeperhour: "Consumo por hora",
  timedriving: "Tiempo de manejo",
  timeralenti: "Tiempo en vacío",
  finalfuel: "Combustible final",
  timepto: "Tiempo PTO",
  percentPto: "Porcentaje PTO",
  fuelpto: "Combustible PTO",
  speedpromedy: "Velocidad promedio",
  speedmax: "Velocidad máxima",
  rpmexcess: "Excesos de RPM",
  maxrpm: "RPM máxima",
  exceptions: "Incidentes en tránsito",
  finaldistance: "Distancia final",
  await: "Espere un momento...",
  wrongConfirmation: "No se ha podido generar el reporte, intente de nuevo",
  CreatedBy: "Creador",
  Status: "Estatus",
  MessageReport:
    "El tiempo de entrega del reporte varía de acuerdo a la cantidad de equipos y el tiempo seleccionado",
  PerformanceReport: "Reporte de desempeño",
  ExceptionReport: "Reporte de excepciones",
  //Filter vehicles
  filterVehicleSettings: "Filtro de vehículos a configurar",
  filterVehicles: "Filtro",
  select: "Selección de vehículos",
  dealer: "Distribuidor",
  client: "Cliente",
  account: "Cuenta",
  group: "Grupo",
  all: "Todos",
  save: "Guardar",
  //Menu app drawer
  titleRep: "Reportes colectivos",
  titleABalance: "Comparativo por periodo",
  titleExceptions: "Incidentes en tránsito",
  dashboard: "Resumen",
  commands: "Comandos",
  exit: "Salir",
  logout: "Cerrar sesión",
  tracts: "ALERTRUCK",
  noName: "Sin nombre",
  accountM: "Administrar",
  Users: "Usuarios",
  entailment: "Vinculaciones",
  //Commands

  //Dashboard
  vehiclesD: "Vehículos",
  totalFuel: "Combustible total usado",
  totalRalentiFuel: "Total de combustible usado en vacío",
  exceptionsHome: "Incidentes en tránsito",
  totalDistance: "Distancia total",
  totalPerformance: "Rendimiento promedio",
  TotalEmptyFuel: "Total de combustible en vacío",
  Totaldistancetraveled: "Distancia total recorrida",
  today: "Hoy",
  last: "Últimos",
  days: "Días",
  to: "al",
  //Charts dashboard
  moreRalenti: "Combustible en vacío", /////
  topRalenti: "10 Vehículos con mayor consumo",
  emptyFuel: "Combustible en vacío",
  fuel: "Combustible",
  rangeFuel: "Rango de rendimiento", /////
  menorRendimiento: "Rendimientos",
  topPerformance: "10 Vehículos con menor rendimiento",
  rendimientoFuel: "Rendimiento",
  engineTime: "Tiempo total de motor encendido",
  drive: "En manejo",
  stop: "Detenido",
  ralenti: "En vacío",
  moreTimeEngine: "Tiempo de uso de motor en ralentí",
  topEngine: "10 Vehículos con mayor tiempo de uso de motor",
  hour: "Horas",
  timeEngineOn: "Tiempo con motor encendido",
  velocityExcess: "Excesos de velocidad",
  frenadoBrusco: "Frenado brusco",
  excesoFreno: "Exceso de freno",
  aceleracionB: "Aceleración brusca",
  excessClutch: "Exceso de embrague",
  moreExcept: "10 Vehículos con más incidencias en tránsito",
  topExceptions: "10 Vehículos con más incidencias",
  compSemanal: "Comparativo semanal",
  emtyHours: "Horas en vacío",
  driveHours: "Horas en manejo",
  engineOnWeek: "Comparativo semanal motor encendido",
  distancRoad: "Distancia recorrida",
  distance: "Distancia",
  weekDistances: "Comparativo semanal distancias",
  rendi: "Rendimiento",
  weekRend: "Comparativo semanal rendimiento",
  time: "Tiempo",
  //Account Billings

  //Exceptions
  ExcessSpeed: "Excesos de velocidad",
  BrusqueAccelerations: "Aceleraciones bruscas",
  Brushesbrakes: "Frenados bruscos",
  ExcessMotorLoad: "Exceso de carga de motor",
  ExcessRPM: "Exceso de RPM",
  ExcessClutch: "Excesos de embrague",
  SharpTurns: "Vueltas bruscas",
  ExcessEngineHours: "Excesos de horas del motor",
  ExcessEngineVacuum: "Excesos de vacío de motor",
  NumberExceptions: "Cantidad de deficiencias de manejo",

  //Session
  session: "Tu sesión ha expirado",
  //AccountManager page
  addUser: "Agregar nuevo usuario",
  edit: "Editar",
  dateInitial: "Fecha de ingreso",
  actions: "Acciones",
  active: "Activo",
  inactive: "Inactivo",
  deleteUser: "¿Está seguro de eliminar este usuario?",
  editUser: "Nombre",
  newUser: "Nuevo usuario",
  editUserTitle: "Editar usuario",
  permissions: "No cuenta con permisos, contacte al administrador",
  selectDealer: "Seleccione un distribuidor",
  //Settings
  personalization: "Personalización",
  darkMode: "Modo obscuro",
  changeLogo: "Cambiar logotipo",
  changeTheme: "Cambiar tema",
  SystemofUnits: "Sistema de unidades",
  distance: "Distancia",
  volume: "Volumen",
  temperature: "Temperatura",
  initialSettings: "Configuración inicial",
  //charts
  printChart: "Imprimir gráfica",
  downloadPNG: "Descargar imagen PNG",
  downloadJPEG: "Descargar imagen JPEG",
  downloadPDF: "Descargar documento PDF",
  downloadSVG: "Descargar imagen SVG",
  liters: "Litros",
  gallons: "Galones",
  errorDashboard: "Sin datos, intente más tarde",

  //Exceptions titles
  ev445: "Exceso de RPM", //-
  ev446: "Apagado en menos de X segundos", //-
  ev447: "Exceso de tiempo de embrague, en movimiento", //-
  ev448: "Exceso de tiempo de embrague, detenido", //-
  ev449: "Exceso de tiempo de freno", //-
  ev450: "Exceso de carga del motor", //-
  ev451: "Alto porcentaje de tiempo ralentí", //-
  ev452: "Aceleración brusca", //-
  ev453: "Frenado brusco", //-
  ev454: "Bajo porcentaje de control crucero", //-
  ev455: "Alerta de tiempo en ralentí", //-
  ev456: "Tiempo de descanso insuficiente", //-
  ev457: "Exceso de velocidad medio", //-
  ev458: "Exceso de velocidad severo", //-
  ev459: "Baja economía de combustible", //-
  ev463: "PTO detenido", //-
  ev464: "Descanso detectado", //-
  ev463: "PTO detenido", //
  ev466: "Promedio alto de carga del motor ", //-
  ev467: "Detección de codigos de error", //-
  ev470: "Data bus desconectado", //-
  ev471: "Data bus reconectado", //-
  ev472: "Data bus incorrecto seleccionado", //-
  ev479: "Inicio de PTO en movimiento", //-
  ev480: "Fin de PTO en movimiento", //-
  otros: "Otros",
  ///testing jema
  ev1: "Consumo en manejo",
  ev2: "Consumo PTO",
  ev3: "Consumo ralenti",

  //Offline
  offline: "Sin conexión a internet",
  online: "Conexión a internet",
  later: "Sin conexión a internet, intente mas tarde",

  loadingData: "Cargando datos, esta operación puede tardar algo de tiempo...",
  lateData: "La operación esta tardando mas de lo normal, por favor espere... ",
  loadingTable: "Cargando... por favor espere",

  noReport: "Seleccione al menos un reporte",

  //Errores de servidor
  autorization: "Usuario sin autorización",
  error400: "Ocurrió un error, intente mas tarde",

  errorFechas:
    "El periodo seleccionado no debe ser mayor a 60 días de operación. Por favor verifique...",
  selectDate: "Selección de fechas",
  invalidDate: "Periodo inválido",
  userSelect: "Seleccione un usuario",
  existinguser: "Usuario seleccionado ya existe",
  addeduser: "Usuario agregado",
  userSelect: "Seleccione un usuario",
  from: "Del",
  to: "al",
  notExist: "No se encontro el usuario",
  docNotExist: "No se encontro el reporte",
  notExistData: "No se encontraron datos",
  addToList: "Agregar a la lista",
  deleteVehicle: "Eliminar de la lista",
  quickList: "Lista rápida",

  sizeImage: "El peso máximo de imagen es de 5 MB",
  alertImage: "La imagen supera los 5 MB",
  deleteAllReports:
    "¿Está seguro de eliminar todos los reportes seleccionados?",

  invalidName: "Nombre de reporte inválido",

  //entailment
  manageoperators: "Administrar operadores",
  addoperator: "Agregar operador",
  entail: "Vinculados",
  noEntail: "No vinculados",
  operator: "Operador",
  license: "Licencia",
  type: "Tipo",
  licenseExpiration: "Expiración de licencia",
  address: "Dirección",
  registrationDate: "Fecha de registro",
  bonding: "Vinculación",
  administration: "Administración",
  link: "Vincular",
  unlink: "Desvincular",
  operators: "Operadores",
  newOperator: "Nuevo operador",
  typeoflicense: "Tipo de licencia",
  expirationDate: "Fecha de expiración",
  required: "Campo requerido",
  editOperator: "Editar operador",
  deleteConfirmOp: "¿Está seguro de eliminar este operador?",
  restrictionTravel:
    "No es posible eliminar este operador porque se encuentra en viaje",
  restrictionMovil:
    "No es posible eliminar este operador porque tiene un móvil vinculado",
  selectMobileEquipment: "Seleccionar equipo móvil",
  other: "Otro",

  //Mobiles
  manageMobileTeams: "Administrar equipos móviles",
  mobiles: "Móviles",
  mobileEquipment: "Equipo Móvil",
  model: "Modelo",
  OS: "SO",
  addEquipment: "Agregar equipo",
  number: "Número",
  brand: "Marca",
  operativeSystem: "Sistema Operativo",
  scanPhone: "Escanear en teléfono a registrar",
  newMobileEquipment: "Nuevo equipo móvil",
  editMobileTeam: "Editar equipo móvil",
  deleteConfirmMo: "¿Está seguro de eliminar este móvil?",
  restrictionTravelM:
    "No es posible eliminar este equipo móvil porque se encuentra en viaje",
  restrictionOperator:
    "No es posible eliminar este equipo móvil porque tiene un operador vinculado",
  selectAnOperator: "Selecciona un operador",
  serialNumber: "Número de serie",

  //Trafico
  traffic: "Tráfico",
  inTravel: "En ruta",
  noTrip: "Viajando, sin ruta",
  stopped: "Estacionado",
  outOfService: "Fuera de servicio",
  operatingStates: "Estados de operación",
  allStates: "Todos los estados",
  vehicle: "Vehículo",
  lastPosition: "Última posición",
  dateT: "Fecha",
  idleTime: "Tiempo inactivo",
  statusT: "Estado",
  newTrip: "Nuevo viaje",
  inProgress: "En curso",
  finalize: "Finalizar",
  assignTrip: "Asignar viaje",
  travels: "Viajes",
  nameOfTheTrip: "Nombre del viaje",
  pathName: "Nombre de ruta",
  origin: "Origen",
  destination: "Destino",
  start: "Inicio",
  finish: "Fin",
  editTrip: "Editar viaje",
  map: "Mapa",
  updatePosition: "Actualizar posición",
  deleteTravel: "¿Está seguro de eliminar este viaje?",
  finishTravel: "¿Está seguro de finalizar este viaje?",
  //Carriers
  businessName: "Nombre comercial",
  serviceType: "Tipo de servicio",
  modality: "Modalidad",
  addCarrier: "Agregar carrier",
  noCarrier: "Seleccione al menos un carrier",
  unknown: "Desconocido",
  cargoGeneral: "Carga general",
  hazardousMaterials: "Materiales y residuos peligrosos",
  alimentaryGrade: "Grado alimenticio",
  consolidated: "Consolidado",
  fullCar: "Carro completo",
  carrierManagement: "Gestión de carriers",
  typeOfServices: "Tipos de servicios",
  selectContact: "Seleccionar contacto",
  phone: "Teléfono",
  contact: "Contacto",
  deleteConfirmCa: "¿Esta seguro de eliminar este carrier?",
  deleteAllCarriers:
    "¿Está seguro de eliminar todos los carriers seleccionados?",
  monitoring: "Monitoreo",
  newCarrier: "Nuevo carrier",
  editCarrier: "Editar carrier",
  add: "Agregar",
  seeContacts: "Ver contactos",
  currentlimitTime: "La hora elegida pasa la hora actual",
  lessStartDate: "La fecha inicial debe ser menor a la fecha final",
  dateRange:
    "El periodo seleccionado no debe ser mayor a 7 días de operación. Por favor verifique...",
  error409Trip: "Vehículo/Conductor con viaje en progreso",
  //DetailsAndTable
  drivingConsume: "Consumo en manejo",
  consumptionIdle: "Consumo en vacío",
  consumePTO: "Consumo PTO",
  drivingMotorUsage: "Uso de motor en manejo",
  motorUsageIdle: "Uso de motor en vacío",
  motorUsage: "Uso de motor PTO",
  performance: "Desempeño",
  //In transit incidents details
  conduction: "Conducción",
  operation: "Operación del vehículo",
  logistic: "Logística",
  diagnosticUnit: "Diagnóstico de la unidad",
  categories: "Categorías",
  //performance
  performancePerTime: "Rendimiento del periodo",
  tripEfficiency: "Eficiencia de viaje",
  location: "Ubicación",
  event: "Evento",
  eventDetails: "Detalles de evento",
  exceptionsReport: "Reporte de incidentes en tránsito",
  calGlobal: "Cálculos globales",
  efficiencyAnalisis: "Análisis de eficiencia",
  maxSpeed: "Velocidad máxima",
  avgSpeed: "Velocidad promedio",
  rpmMax: "Rpm máxima",
  rpmAvg: "Rpm promedio",
  breakTime: "Tiempo de descanso",
  tripTime: "Tiempo de viaje",
  motorUsag: "Tiempo de motor",
  expDash: "Exportar dashboard",
  expExcel: "Exportar excel",
  odometer: "Odómetro",
  motorEfficiency: "Análisis de eficiencia del motor",
  velProm: "Velocidad promedio del vehículo",
  velPromRPM: "Velocidad promedio del motor en Rpm",
  cargaProm: "Porcentaje de carga promedio del motor",
  pedalProm: "Promedio de posición pedal en acelerador",
  vel: "Velocidad",
  percent: "Porcentaje",
  consult: "Consultar",
  compare: "Comparar",
  FuelConsumed: "Combustible consumido",
  EngineUsageTime: "Tiempo de uso de motor",
  BadHabits: "Malos hábitos de manejo detectados",
  FuelControlCrucero: "Combustible control crucero",
  TimeControlCrucero: "Tiempo control crucero",
  DistanceControlCrucero: "Distancia control crucero",
  cruiseActive: "Control crucero activo",
  cruiseInactive: "Control crucero inactivo",
  BrakeCounter: "Freno",
  ClutchCounter: "Exceso de embrague",
  RoughBrakeCounter: "Exceso de freno",
  ExcessRpmCounter: "Exceso de Rpms",
  notAvailable: "No disponible",
  threshold: "Umbral",
  ptoElapsed: "Tiempo PTO transcurrido",
  engineShutOff: "Tiempo al que se apagó el motor",
  clutchTime: "Tiempo de embrague",
  thresholdSpeed: "Umbral de velocidad",
  thresholdTime: "Tiempo umbral",
  brakeTime: "Tiempo de freno",
  initialSpeed: "Velocidad inicial",
  finalSpeed: "Velocidad final",
  excessLoadT: "Tempo de exceso de carga",
  loadMax: "Carga máxima",
  umbralPercentage: "Porcentaje umbral",
  ralentiTime: "Tiempo ralentí",
  ralentiF: "Combustible ralentí",
  accelerationMax: "Aceleración máxima detectada",
  decelerationMax: "Desaceleración máx",
  percentageCC: "Porcentaje de control crucero",
  fuelEconomy: "Economía de combustible",
  timeAt: "TTiempo en",
  initialTotFuel: "Combustible total inicial",
  avgLoadPercentage: "Porcentaje promedio de carga detectado",
  thresholdAvgLoadP: "Umbral porcentaje de carga promedio",
  initialFuelIdle: "Combustible en vacío inicial",
  frequency: "Frecuencia",
  busReal: "Correct data bus",
  selectedBus: "Selected data bus",
  //comparative
  comparative: "Comparativo",
  efficiency: "Eficiencia",
  tripAnalysis: "Análisis de eficiencia por viajes",
  tripInTransitIncidents: "Incidentes en tránsito por viajes",
  distribution: "Distribución de incidentes en tránsito",
  trip: "Viaje",
  timeEngine: "Tiempo de uso de motor",
  fuelConsumed: "Combustible consumido",
  tripDuration: "Duración del viaje",
  minSpeed: "Mínima velocidad",
  minRpm: "RPM mínima",
  maxRpmm: "RPM máxima",
  AvgMotorLoad: "Promedio de carga de motor",
  noTrips: "Periodo sin viaje asignado",
  noTripSelected: "Seleccione un viaje",
  fuelConsum: "Consumo de combustible",
  vol: "Volumen",
  finalOdometer: "Odómetro final",
  finalFuel: "Combustible final",
  excededData:
    "La información solicitada es demasiado para el almacenamiento local",
  PerformancePerHour: "Rendimiento por hora",
  DrivingPerformance: "Rendimiento de manejo",
  maxSpeedDate: "Fecha de velocidad máxima",
  lat: "Latitud",
  lon: "Longitud",
  descripcion: "Descripción",
  engineHistory: "Historial de uso de motor",
  tripsHistory: "Historial de viajes",
  //Individual fuel
  individualFuel: "Combustible individual",
  selectVehicle: "Seleccionar vehículo",
  update: "Actualizar",
  newFrom: "De",
  newTo: "A",
  recentActivity: "Actividad reciente",
  magnitudeOfChange: "Magnitud de cambio",
  selectPeriod: "Seleccionar periodo",
  seeMore: "Ver más",
  noVehicle: "Sin vehículo seleccionado",
  locationUnavailable: "Ubicación no disponible",
  noDataFP: "No hay datos para rendimiento y actividad reciente",
  noDataRL:
    "No hay datos para el último nivel de combustible reportado por el vehículo seleccionado",
  legendPerformance: "Rendimiento promedio",
  noResultsFound: "No se han encontrado resultados para la ubicación dada",
  geoFailed: "La geocodificación inversa falló debido a: ",
  loadingLocation: "Cargando ubicación... por favor espere",
  noDetailsData: "No hay datos disponibles",
  //descripcion de eventos
  distanceTraveled: "Distancia recorrida durante el evento",
  timeElapsed: "Tiempo transcurrido durante el evento",
  maxVal: "Valor máximo detectado",
  minTime: "Tiempo mínimo de apagado",
  detectedShutdown: "Tiempo de apagado detectado",
  timeDetected: "Tiempo detectado",
  speedAtTheStart: "Velocidad al inicio del evento",
  speedAtTheEnd: "Velocidad al final del evento",
  thresholdLoadP: "Porcentaje de carga umbral",
  percentageOfMaxLoad: "Porcentaje de carga máxima detectada",
  tripThresholdTime: "Tiempo umbral de viaje",
  idleTimeElapsed: "Tiempo ralentí transcurrido desde el inicio de viaje",
  fuelConsumedIdleMark: "Combustible consumido en ralentí desde la marca",
  thresholdAcceleration: "Aceleración umbral",
  thresholdBraking: "Frenado umbral",
  maxBrakingD: "Frenado máximo detectado",
  percentageDetected: "Porcentaje detectado",
  drivingThresholdTime: "Tiempo umbral de manejo",
  drivingTimeElapsed: "Tiempo transcurrido de manejo",
  minPerformance: "Rendimiento mínimo",
  performanceDetected: "Rendimiento detectado",
  totalFuelConsumption: "Consumo de combustible total",
  fuelConsumptionIdle: "Consumo de combustible ralentí",
  thresholdAvgLoad: "Carga promedio umbral",
  //rastreo
  tracking: "Rastreo",
  timeWT: "Tiempo sin transmitir",
  checkStatus: "Consultar estatus",
  centerOnMap: "Centrar en mapa",
  sendCommand: "Enviar comando",
  seeAct: "Ver actividad",
  seeStatus: "Ver estatus",
  loadingTextForTable: "Cargando... Por favor espere",
  addressNotAvailable: "Dirección no disponible",
  plates: "Placas",
  year: "Año",
  color: "Color",
  category: "Categoría",
  comments: "Comentarios",
  operatingState: "Estado de operación",
  gps: "GPS",
  server: "Servidor",
  position: "Posición",
  battery: "Batería",
  gsm: "GSM",
  realPosition: "Posición real",
  connectedToServer: "Conectado al servidor",
  autoReports: "Auto reportes",
  switch: "Switch de ignición",
  ignitionLocked: "Bloqueo ignición",
  panicButton: "Botón de pánico",
  engineLocked: "Bloqueo de motor",
  supportButton: "Botón de soporte",
  lightSiren: "Luces/Sirena",
  helpButton: "Botón de ayuda",
  doorsLocked: "Cerrar seguros",
  batteryConnected: "Batería conectada",
  doorsUnlocked: "Abrir seguros",
  activity: "Actividad",
  engine: "Motor",
  connection: "Conexión",
  geoReference: "Referencia geográfica",
  iO: "Entradas/Salidas",
  vehAttributes: "Atributos del vehículo",
  reports: "Reportes",
  preferences: "Preferencias",
  confirmedCommand: "Comando confirmado",
  confirmEngineLock: "Confirmar bloqueo de motor",
  lastTransmission: "Última transmisión",
  onGoing: "Movimiento",
  stopped: "Detenido", //se agrega otro porque la traduccion en ingles es diferente
  noteVehState: "Nota: Se estuvo moviendo en el mismo lugar",
  valueNotAvailable: "Valor no disponible por el momento",
  distanceNotAvailable: "Distancia no disponible por el momento",
  durationNotAvailable: "Duración no disponible por el momento",
  fuelNotAvailable: "Combustible no disponible por el momento",
  since: "Desde",
  noResponse: "Sin respuesta",
  altavozOn: "Activar altavoz",
  altvozOff: "Desctivar altavoz",
  callOn: "Activar llamada",
  tmReportsOn: "Activar reportes (TM-FIL-DIST)",
  tmReportsOff: "Desactivar reportes (TM-FIL-DIST)",
  reportVel: "Reporte por exceso de Velocidad",
  reportIgnicion: "Reporte de switch de ignición",
  repStartStop: "Rep. Detenido/en marcha",
  repGpsState: "Reporte de estado del Gps",
  getImei: "Consultar IMEI",
  getSimData: "Consultar datos del SIM Card",
  lockEngine: "Bloquear Motor",
  unlockEngine: "Desbloquear Motor",
  resetES: "Restablecer E/S",
  resetButtons: "Restablecimiento de botones",
  freeText: "Texto libre",
  confirmCommand: "Confirmar envío de comando",
  noActivityData: "Sin datos para el periodo seleccionado",
  selectCommand: "Seleccione un comando",
  //HOME
  firmwareManager: "Firmware Manager",
  firmware: "Firmware",
  manager: "Manager",
  bosonUser: "Usuario Boson",
  master: "Master",
  updateConfig: "Configuración de actualización",
  upd: "Actualización",
  config: "Configuración de",
  export: "Exportar",
  selectFile: "Seleccionar archivo",
  uploadFile: "Subir archivo",
  selectFirmwareV: "Seleccionar una versión de firmware",
  chooseFile: "Elegir archivo",
  noFileSelected: "Ningún archivo seleccionado",
  fileName: "Nombre del archivo",
  enterFileName: "Ingrese el nombre del archivo para su registro",
  firmwareVersion: "Versión de firmware",
  dateOfLastUpdate: "Fecha de última actualización",
  requiredName: "Nombre requerido",
  requiredFile: "Archivo requerido",
  confirmation: "Confirmación",
  continueUpdate: "¿Desea continuar la actualización?",
  theFollowingVeh: "Los siguientes vehículos se actualizarán con la versión",
  selectAtLeasOnetVeh: "Favor de seleccionar al menos un vehículo",
  versionRequired: "Versión requerida",
  cancelUpdate: "¿Desea cancelar la actualización?",
  yes: "Sí",
  no: "No",
  details: "Detalles",
  masterFunction: "Función no disponible para el nivel de acceso seleccionado",
  requestedBy: "Solicitante",
  updParameters: "Parámetros de actualización",
  securityCode: "Código de seguridad",
  maxWaitingTimePerCommand: "Tiempo máximo de espera por comando",
  timeInSec: "Tiempo en segundos",
  NumOfAttempsPerCommand: "Número de intentos por comando",
  NumOfAttemps: "Número de intentos",
  selectFirmwareVersionFromDevice: "Seleccionar una versión de firmware desde su dispositivo",
  addNew: "Agregar nueva",
  statusDate: "Fecha de estatus",
  processTime: "Tiempo en proceso",
  operationInProgress: "Operación en progreso",
  sendingUpd: "Enviando actualización...",
  updSent: "Actualización enviada, revisar apartado 'En curso'.",
  visit: "Visitar",
  deleteFirmwareVersionConfirm: "¿Está seguro de eliminar esta versión de firmware?",
  updParametersNote: "Nota: Por el momento solo está disponible la actualización para equipos TRK7, Phoenix 4G y MTT.",
  updParametersNote2: "Nota: Por el momento solo está disponible la actualización para equipos PC41.",
  deviceOnline: "En línea",
  checkVersion: "Favor de revisar que la versión seleccionada corresponda con el ",
  replaceVersion: "El archivo ya existe, ¿desea reemplazarlo?",
  addFirmwareVersionConfirm: "¿Desea agregar esta versión de firmware?",
  versionDeletedWithSuccess: "Versión de firmware eliminada con éxito",
  versionAddedWithSuccess: "Versión de firmware agregada con éxito",
  addingVersion: "Agregando versión",

  //States of FW devices
  updated: "Actualizado",
  pending: "Pendiente",
  unknown: "Desconocido",
  isInProgress: "En progreso",
  canceled: "Cancelado",
  failed: "Ocurrió un error",

  //ORDERS LIST
  orders: "Órdenes",
  ordersList: "Lista de órdenes",
  retry: "Reintentar",
  seeDetails: "Ver detalles",
  modify: "Modificar",
  orderId: "Id de orden",
  failedDevices: "Vehículos fallidos",
  order: "Orden",
  confirmRetryOrder: "¿Está seguro que desea reintentar orden?",
  confirmModifyOrder: "¿Está seguro que desea modificar orden?",
  confirmDeleteOrder: "¿Está seguro que desea eliminar orden?",
};

export default es;
